import gql from 'not-graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  ExperianCreditCheckRequest,
  ExperianCreditCheckResponse,
} from '../__generated__/pge-types';

const EXPERIAN_CREDIT_CHECK_QUERY = gql`
  query ExperianCreditCheck($param: ExperianCreditCheckRequest!) {
    experianCreditCheck(param: $param) {
      isFraud
      notFound
      hasError
    }
  }
`;

export const useExperianCreditCheckQuery = () => {
  const { refetch, loading, error, data } = useQuery<
    { experianCreditCheck: ExperianCreditCheckResponse },
    { param: ExperianCreditCheckRequest }
  >(EXPERIAN_CREDIT_CHECK_QUERY, {
    skip: true,
  });
  return {
    refetch,
    loading,
    error,
    data,
  };
};

export default useExperianCreditCheckQuery;
