import Container from '@material-ui/core/Container';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3, 16, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 10, 2),
      },
    },
  }),
);

interface Page2ColumnLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  leftColumn?: JSX.Element;
  dense?: boolean;
}

const Page2ColumnLayout: FunctionComponent<Page2ColumnLayoutProps> = ({
  leftColumn,
  dense,
  children,
}) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid
        container
        direction="row"
        spacing={2}
        justify={!leftColumn ? 'center' : 'flex-start'}
      >
        {leftColumn && (
          <Hidden smDown>
            <Grid item md={3}>
              {leftColumn}
            </Grid>
            {!dense && <Grid item md={1}></Grid>}
          </Hidden>
        )}
        {!leftColumn && !dense && (
          <Hidden smDown>
            <Grid item md={1}></Grid>
          </Hidden>
        )}
        <Grid item xs={12} md={!leftColumn ? 7 : dense ? 9 : 7}>
          {children}
        </Grid>
        <Hidden smDown>{!dense && <Grid item md={1}></Grid>}</Hidden>
      </Grid>
    </Container>
  );
};

export default Page2ColumnLayout;
