import { useApolloClient } from '@apollo/react-hooks';
import { isEmailExistQuery } from '../queries/changeEmail.query';

export const useIsEmailExists = () => {
  const apolloClient = useApolloClient();
  async function isEmailExists(newEmail: string): Promise<boolean> {
    const queryResult = await apolloClient.query({
      query: isEmailExistQuery,

      variables: { newEmail },
    });

    return !!queryResult?.data?.isEmailExist?.exist;
  }
  return { isEmailExists };
};
