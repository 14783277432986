import {
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { navigate, RouteComponentProps, Router } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import ProgressTracker from '../../../components/ProgressTracker';
import {
  ALLOWED_RES_METER_TYPES,
  createAddressAndDateFormAdapter,
  createAddressAndDateValidateFunction,
  SSMAddressAndDateForm,
} from '../../../components/ssm/common/SSMAddressAndDateForm';
import {
  createSSMVerifyInfoFormAdapter,
  createVerifyInfoValidateFunction,
  SSMVerifyInfoForm,
} from '../../../components/ssm/common/SSMVerifyInfoForm';
import {
  createContactInfoFormAdapter,
  createContactInfoValidateFunction,
  SSMContactInfoForm,
} from '../../../components/ssm/start/SSMContactInfoForm';
import { SSMConfirmDetails } from '../../../components/ssm/start/SSMConfirmDetails';
import { SSMServiceSummary } from '../../../components/ssm/common/SSMServiceSummary';
import { SSMStartServiceSuccess } from '../../../components/ssm/start/SSMStartServiceSuccess';
import { SSMStartServiceIneligible } from '../../../components/ssm/start/SSMStartServiceIneligible';
import Page2ColumnLayout from '../../../components/utility/page2-column-layout';
import { Wizard } from '../../../components/utility/wizard/Wizard';
import { WizardStep } from '../../../components/utility/wizard/WizardStep';
import useSSMStartService, {
  AddressAndDate,
  ContactInfo,
  VerifyInfo,
} from '../../../hooks/useSSMStartService';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import {
  Meter,
  ServiceSummary,
  ServiceSummaryType,
  HomeDetails,
} from '../../../components/ssm/ssm.types';
import useValidatePremiseQuery from '../../../hooks/useValidatePremiseQuery';
import {
  EligibilityInfo,
  PremiseEligibility,
} from '../../../components/ssm/common/PremiseEligibility/PremiseEligibility';
import Backdrop from '../../../components/backdrop';
import useUtil from '../../../components/need-more-time-to-pay/useUtil';
import {
  PersonVerificationType,
  QueryValidatePersonArgs,
  ValidatePersonContactValueInput,
  ValidatePremiseResponse,
  Maybe,
  ValidatePersonResponse,
  PremiseDetail,
  PersonIdentificationCheckInput,
  PersonPrimaryIdType,
  ExperianCreditCheckRequest,
  StartSubmitResponse,
  QuickAddressSearchParams,
  ValidatePremiseRequest,
  CustomerContactInput,
  StartMoveEligibility,
} from '../../../__generated__/pge-types';
import useValidatePersonQuery from '../../../hooks/useValidatePersonQuery';
import useAuth from '../../../hooks/useAuth';
import {
  SSMHomeDetailsForm,
  createSSMMoveHomeDetailsFormAdapter,
  createHomeDetailsValidateFunction,
} from '../../../components/ssm/common/SSMHomeDetailsForm';
import useIsPersonalIdValid from '../../../hooks/useIsPersonalIdValid';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import useExperianCreditCheckQuery from '../../../hooks/useExperianCreditCheckQuery';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { QasResult } from '../../../hooks/useQas';
import { useIsEmailExists } from '../../../hooks/useIsEmailExists';
import authService from '../../../lib/authService';
import { useCreateCustomerContact } from '../../../hooks/useCreateCustomerContact';
import {
  CustContactCharacteristicType,
  CustContactCharacteristicValue,
  CustContactClass,
  CustContactType,
} from '../../../hooks/useSSMStopService';
import useGetAccountEligibiltyQuery from '../../../hooks/useGetAccountEligibiltyQuery';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3, 16, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 10, 2),
      },
    },
    mobileSummary: {
      padding: '2em',
      paddingTop: '0',
      marginTop: '-1px',
    },
    fullWidth: {
      width: '100%',
    },
    containerWidth: {
      maxWidth: '500px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  }),
);

type ValidatePremiseData = {
  validatePremise: ValidatePremiseResponse[];
};

const SSMStartService: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const classes = useStyles();
  const { t, inlineRichT } = useTranslation();
  const { setErrorNotification } = useUtil();
  const { isAuthenticated } = useAuth();
  const { wrapWithLoader } = useWrapWithLoader();
  const {
    ssmStartServiceState,
    setAddressAndDate,
    setContactInfo,
    doVerifyAddress,
    concatenateAddressFields,
    setVerifyInfo,
    setHomeDetails,
    onConfirm,
    doVerifyQASAddress,
  } = useSSMStartService();

  const { refetch: validatePremise } = useValidatePremiseQuery();
  const { refetch: validatePerson } = useValidatePersonQuery();
  const { refetch: experianCreditCheck } = useExperianCreditCheckQuery();
  const { validateLastFourDigitPersonId } = useIsPersonalIdValid();
  const {
    customer: customerInfoData,
    loading: customerInfoLoading,
    error: customerInfoError,
  } = useAccountCustomer();
  const { refetch: validateAccount } = useGetAccountEligibiltyQuery();
  const { isEmailExists } = useIsEmailExists();
  const { submit: createWebCustomerContact } = useCreateCustomerContact();

  const pathname = props.location?.pathname;
  const signInWithCustomToken = authService.signInWithCustomToken.bind(
    authService,
  );

  const [serviceSummary, setServiceSummary] = useState<ServiceSummary>({
    type: ServiceSummaryType.NEW,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [validateAccountLoading, setValidateAccountLoading] = useState<boolean>(
    isAuthenticated,
  );
  const [personId, setPersonId] = useState<string>('');
  const [validatePremiseData, setValidatePremiseData] = useState<
    ValidatePremiseData
  >();
  const [validatePersonData, setValidatePersonData] = useState<
    ValidatePersonResponse
  >();

  const [currentPosition, setCurrentPosition] = useState<
    google.maps.LatLngLiteral | undefined
  >();

  const [isAddressVerified, setIsAddressVerified] = useState<boolean>(false);
  const [isRenewablesEligible, setIsRenewablesEligible] = useState<boolean>(
    false,
  );
  const [encrPersonId, setEncrPersonId] = useState<string>('');
  const [isMeterSelectionVerified, setIsMeterSelectionVerified] = useState<
    boolean
  >(false);

  const [loginEmail, setLoginEmail] = useState<string | undefined>();
  const [registeredCssUser, setRegisteredCssUser] = useState<
    string | undefined
  >();

  const [
    isAddressSuggestionModalDisplayed,
    setIsAddressSuggestionModalDisplayed,
  ] = useState<boolean>(false);

  const [autoCompleteAddress, setAutoCompleteAddress] = useState<string>('');
  const [personName, setPersonName] = useState<string>('');
  const [startServiceSubmitResponse, setStartServiceSubmitResponse] = useState<
    StartSubmitResponse
  >();
  const [isExistingPerson, setIsExistingPerson] = useState<boolean>(false);
  const [multiplePremisesIneligible, setMultiplePremisesIneligible] = useState<
    boolean
  >(false);

  const [
    isMultipleMetersModalDisplayed,
    setIsMultipleMetersModalDisplayed,
  ] = useState<boolean>(false);

  const [
    isPremiseEligibilityModalDisplayed,
    setIsPremiseEligibilityModalDisplayed,
  ] = useState<boolean>(false);

  const [
    isCustomerFoundModalDisplayed,
    setIsCustomerFoundModalDisplayed,
  ] = useState<boolean>(false);

  const [invalidLastFourDigitsError, setInvalidLastFourDigitsError] = useState<
    boolean
  >(false);

  const [metersInfo, setMetersInfo] = useState<Meter[]>([]);
  const [qasData, setQasData] = useState<null | {
    params: QuickAddressSearchParams;
    results: QasResult;
  }>();
  const [idVerificationFailureCount, setIdVerificationFailureCount] = useState<
    number
  >(0);

  const [isExistingEmail, setIsExistingEmail] = useState<boolean>(false);
  const [eligibilityInfo, setEligibilityInfo] = useState<EligibilityInfo>({
    isEligible: true,
    spTypeNotResidential: false,
    pendingDisconnect: false,
    meterStatusInactive: false,
  });

  useEffect(() => {
    if (customerInfoError) {
      setErrorNotification(true);
    } else {
      setErrorNotification(false);
    }
  }, [customerInfoError]);

  useEffect(() => {
    async function fetchPersonInfo() {
      if (customerInfoData) {
        const contactInfo: ContactInfo = {};
        const fullName = customerInfoData.personName;
        contactInfo.firstName = fullName?.split(', ')[1]?.split(' ')[0];
        contactInfo.middleName = fullName?.split(', ')[1]?.split(' ')[1];
        contactInfo.lastName = fullName?.split(', ')[0];
        const { contactDetails } = customerInfoData;
        contactDetails?.forEach(contactItem => {
          if (
            contactItem?.contactType === 'EMAIL' ||
            contactItem?.contactType === 'BEMAIL'
          ) {
            contactInfo.email = contactItem.contactValue;
          }
          if (contactItem?.contactType === 'PNP') {
            contactInfo.primaryPhone = contactItem.contactValue;
          }
          if (contactItem?.contactType === 'MOB') {
            contactInfo.mobilePhone = contactItem.contactValue;
          }
          if (contactItem?.contactType === 'ALT') {
            contactInfo.altPhone = contactItem.contactValue;
          }
        });
        setPersonId(customerInfoData.personId);
        setEncrPersonId(customerInfoData.encryptedPersonId);
        setContactInfo(contactInfo);
        try {
          setLoading(true);
          const contactValues: Array<Maybe<
            ValidatePersonContactValueInput
          >> = [];
          if (Number(contactInfo.primaryPhone?.length) > 0) {
            contactValues.push({
              value: String(contactInfo.primaryPhone),
              type: PersonVerificationType.Pnp,
            });
          }
          if (Number(contactInfo.mobilePhone?.length) > 0) {
            contactValues.push({
              value: String(contactInfo.mobilePhone),
              type: PersonVerificationType.Mob,
            });
          }
          if (Number(contactInfo.altPhone?.length) > 0) {
            contactValues.push({
              value: String(contactInfo.altPhone),
              type: PersonVerificationType.Alt,
            });
          }
          const params: QueryValidatePersonArgs = {
            payload: {
              firstName: String(contactInfo.firstName),
              lastName: String(contactInfo.lastName),
              contactValues,
            },
          };
          if (Boolean(contactInfo?.middleName)) {
            params.payload.middleName = String(contactInfo?.middleName);
          }
          const result = await validatePerson(params);
          if (
            !result.loading &&
            !result.errors &&
            Object.keys(result.data).length > 0
          ) {
            setValidatePersonData(result.data.validatePerson);
            setLoading(false);
          }
        } catch {
          setLoading(false);
          setErrorNotification(true);
        }
        const perId = customerInfoData.personId;
        if (perId.length > 0) {
          validateAccount({
            params: {
              perId,
            },
          })
            .then(result => {
              if (
                !result.loading &&
                !result.errors &&
                Object.keys(result.data).length > 0
              ) {
                if (
                  result.data.getAccountEligibilty.isBadDebt ||
                  result.data.getAccountEligibilty.isFraud
                ) {
                  setPersonName(customerInfoData.personName || '');
                  void navigate(ROUTES.SSM_START_INELIGIBLE, {
                    state: { errorType: 'isBadDebtisFraud' },
                    replace: true,
                  });
                }
                setValidateAccountLoading(false);
              }
            })
            .catch(() => {
              setValidateAccountLoading(false);
              setErrorNotification(true);
            });
        } else {
          setValidateAccountLoading(false);
          setErrorNotification(true);
        }
      }
    }
    if (!startServiceSubmitResponse?.isStartSuccess) {
      void fetchPersonInfo();
    }
  }, [customerInfoData]);

  useEffect(() => {
    const meters: string[] = [];
    let meterIndex: number = 0;
    metersInfo
      .filter((meter: Meter) => meter.checked)
      .forEach((meter: Meter) => {
        if (meter.spType === ALLOWED_RES_METER_TYPES[0]) {
          ++meterIndex;
          meters.push(`${t('METER')} ${meterIndex}: ${t('RESIDENTIAL')}`);
        }
        if (meter.spType === ALLOWED_RES_METER_TYPES[1]) {
          ++meterIndex;
          meters.push(`${t('METER')} ${meterIndex}:  ${t('AREA_LIGHT')}`);
        }
      });
    let fullName: string = '';
    if (ssmStartServiceState?.contactInfo) {
      fullName = `${ssmStartServiceState?.contactInfo?.firstName ??
        ''} ${ssmStartServiceState?.contactInfo?.middleName ??
        ''} ${ssmStartServiceState?.contactInfo?.lastName ?? ''}`.trim();
    }
    setServiceSummary(summary => ({
      ...summary,
      ...(Boolean(fullName) ? { name: fullName } : {}),
      ...(ssmStartServiceState?.addressAndDate?.address
        ? { address: ssmStartServiceState?.addressAndDate?.address }
        : {}),
      ...(meters.length > 0 ? { meters } : {}),
      ...(ssmStartServiceState?.addressAndDate?.date
        ? { date: ssmStartServiceState?.addressAndDate?.date }
        : {}),
    }));
  }, [ssmStartServiceState]);

  useEffect(() => {
    if (personName.length > 0) {
      setServiceSummary({
        type: ServiceSummaryType.NEW,
        name: personName,
      });
    }
  }, [personName]);

  const handleAddressAndDate = async (addressAndDate: AddressAndDate) => {
    if (multiplePremisesIneligible) {
      if (isAuthenticated) {
        setLoading(true);
        const payload = {
          accountNumber: '',
          longDescription: `Multiple Premises, Address: ${autoCompleteAddress}`,
          encryptedPersonId: encrPersonId,
          contactClass: CustContactClass.WSS,
          contactType: CustContactType.WEBCK,
          characteristics: [
            {
              type: CustContactCharacteristicType.CMCHNNL,
              value: CustContactCharacteristicValue.CSWEB,

              action: '',
            },
          ],
          shouldPrintLetter: false,
        };
        const result = await createWebCustomerContact(payload);
        if (!result?.errors && result?.data?.createWebCustomerContact) {
          setLoading(false);
        }
        if (result?.errors) {
          setLoading(false);
          setErrorNotification(true);
        }
      }

      setServiceSummary(summary => ({
        type: ServiceSummaryType.NEW,
        name: summary?.name,
      }));

      void navigate(ROUTES.SSM_START_INELIGIBLE, {
        state: { errorType: 'multiplePremises' },
      });
      return false;
    }
    if (
      await doVerifyAddress(
        isAddressVerified,
        autoCompleteAddress,
        setIsAddressSuggestionModalDisplayed,
        setIsMultipleMetersModalDisplayed,
        metersInfo,
        isMeterSelectionVerified,
        addressAndDate.address,
      )
    ) {
      try {
        setLoading(true);
        let proceedNext: boolean = false;
        const payload: Array<Maybe<PremiseDetail>> = [];
        const isEveryMeterDisabled = metersInfo.every(
          meter => meter.disabled === true,
        );
        metersInfo.forEach((meter: Meter) => {
          let item: Maybe<PremiseDetail>;
          const index = payload.findIndex(
            payloadItem => payloadItem?.premId === meter.premiseId,
          );
          if (index === -1) {
            item = {
              premId: meter.premiseId,
              spList: [meter.spId],
            };
            if (!meter.disabled && meter.checked && !isEveryMeterDisabled) {
              payload.push(item);
            } else if (isEveryMeterDisabled) {
              payload.push(item);
            }
          } else {
            item = {
              premId: meter.premiseId,
              spList: [...payload[index]!.spList, meter.spId],
            };
            if (!meter.disabled && meter.checked && !isEveryMeterDisabled) {
              payload[index] = item;
            } else if (isEveryMeterDisabled) {
              payload[index] = item;
            }
          }
        });
        const payloadValidatePremise: ValidatePremiseRequest = {
          premiseDetails: payload,
          validateDate: String(
            moment(addressAndDate.date).format('YYYY-MM-DD'),
          ),
        };
        if (isAuthenticated) {
          payloadValidatePremise.perId = personId;
          payloadValidatePremise.serviceAddress =
            addressAndDate?.address?.addressLine1;
        }
        const result = await validatePremise({
          payload: payloadValidatePremise,
        });

        if (
          !result.loading &&
          !result.errors &&
          result.data.validatePremise.length > 0
        ) {
          setValidatePremiseData(result.data);
          if (
            result.data.validatePremise[0].eligibility ===
            StartMoveEligibility.InEligible
          ) {
            void navigate(ROUTES.SSM_START_INELIGIBLE, {
              state: { errorType: 'pendingStartIneligibleError' },
            });
            setLoading(false);
            return false;
          }
          const ltSpIds = metersInfo
            .filter(
              (meter: Meter) =>
                meter.spType === 'E-UB-LT' && meter.isAreaLight === true,
            )
            .map((meter: Meter) => meter.spId);
          setLoading(false);
          if (result.data.validatePremise.length > 0) {
            const _eligibilityInfo: EligibilityInfo = {
              isEligible: true,
              spTypeNotResidential: false,
              pendingDisconnect: false,
              meterStatusInactive: false,
            };
            result.data.validatePremise.forEach(premise => {
              premise.spList?.some(sp => {
                if (!ltSpIds.includes(sp?.spId || '')) {
                  if (sp?.meterStatusInactive) {
                    _eligibilityInfo.isEligible = false;
                    _eligibilityInfo.meterStatusInactive = true;
                  }
                  if (sp?.pendingDisconnect) {
                    _eligibilityInfo.isEligible = false;
                    _eligibilityInfo.pendingDisconnect = true;
                  }
                  if (sp?.spTypeNotResidential) {
                    _eligibilityInfo.isEligible = false;
                    _eligibilityInfo.spTypeNotResidential = true;
                  }
                }
              });
              setIsPremiseEligibilityModalDisplayed(
                !_eligibilityInfo.isEligible,
              );
              setEligibilityInfo(_eligibilityInfo);
              if (_eligibilityInfo.isEligible) {
                proceedNext = true;
              } else {
                setServiceSummary(summary => ({
                  type: ServiceSummaryType.NEW,
                  name: summary?.name,
                }));
              }
            });
          }
        }
        setAddressAndDate(addressAndDate);
        if (isAuthenticated && proceedNext) {
          void navigate(ROUTES.SSM_START_VERIFY);
        } else if (proceedNext) {
          return true;
        }
      } catch {
        setLoading(false);
        setErrorNotification(true);
        return false;
      }
    }
    return false;
  };

  const handleContactInfo = async (contactInfo: ContactInfo) => {
    try {
      setLoading(true);
      setIsExistingEmail(false);
      const email = contactInfo.email?.trim();
      if (email) {
        const isExisting = await isEmailExists(email);
        if (isExisting) {
          setIsExistingEmail(true);
          setLoading(false);
          return false;
        } else {
          setIsExistingEmail(false);
        }
      }
      const contactValues: Array<Maybe<ValidatePersonContactValueInput>> = [];
      if (Number(contactInfo.primaryPhone?.length) > 0) {
        contactValues.push({
          value: String(contactInfo.primaryPhone),
          type: PersonVerificationType.Pnp,
        });
      }
      if (Number(contactInfo.mobilePhone?.length) > 0) {
        contactValues.push({
          value: String(contactInfo.mobilePhone),
          type: PersonVerificationType.Mob,
        });
      }
      if (Number(contactInfo.altPhone?.length) > 0) {
        contactValues.push({
          value: String(contactInfo.altPhone),
          type: PersonVerificationType.Alt,
        });
      }
      const params: QueryValidatePersonArgs = {
        payload: {
          firstName: String(contactInfo.firstName).trim(),
          lastName: String(contactInfo.lastName).trim(),
          contactValues,
        },
      };
      if (Boolean(contactInfo?.middleName)) {
        params.payload.middleName = String(contactInfo?.middleName).trim();
      }
      const result = await validatePerson(params);
      if (
        !result.loading &&
        !result.errors &&
        Object.keys(result.data).length > 0
      ) {
        setValidatePersonData(result.data.validatePerson);
        setLoading(false);
        if (result.data.validatePerson?.isExistingPerson) {
          setIsExistingPerson(true);
          setLoginEmail(result.data.validatePerson.username || '');
          setRegisteredCssUser(result.data.validatePerson.username || '');
          if (
            result.data.validatePerson.isBadDebt ||
            result.data.validatePerson.isFraud
          ) {
            if (
              isAuthenticated &&
              result.data.validatePerson.encryptedPersonId
            ) {
              setLoading(true);
              const payload: CustomerContactInput = {
                accountNumber: '',
                longDescription:
                  'Bad Debt or Fraud Noted For Start Service User',
                encryptedPersonId: result.data.validatePerson.encryptedPersonId,
                contactClass: CustContactClass.WSS,
                contactType: CustContactType.WEBCK,
                characteristics: [
                  {
                    type: CustContactCharacteristicType.CMCHNNL,
                    value: CustContactCharacteristicValue.CSWEB,
                    action: '',
                  },
                ],
                shouldPrintLetter: false,
              };
              const ccResult = await createWebCustomerContact(payload);
              if (
                !ccResult?.errors &&
                ccResult?.data?.createWebCustomerContact
              ) {
                setLoading(false);
                void navigate(ROUTES.SSM_START_INELIGIBLE, {
                  state: { errorType: 'isBadDebtisFraudExperian' },
                });
              }
              if (ccResult?.errors) {
                setLoading(false);
                setErrorNotification(true);
              }
            } else {
              setLoading(false);
              void navigate(ROUTES.SSM_START_INELIGIBLE, {
                state: { errorType: 'isBadDebtisFraudExperian' },
              });
            }
            setContactInfo(contactInfo);
            return false;
          }
          if (result.data.validatePerson.hasOnlineAccount) {
            setIsCustomerFoundModalDisplayed(true);
            setContactInfo(contactInfo);
            return false;
          }
        }
      }
    } catch {
      setLoading(false);
      setErrorNotification(true);
      return false;
    }
    setContactInfo(contactInfo);
    return true;
  };

  const handleVerifyInfo = async (verifyInfo: VerifyInfo) => {
    try {
      setLoading(true);
      const _primaryIdType = validatePersonData?.primaryIdType;
      if (_primaryIdType && _primaryIdType !== PersonPrimaryIdType.None) {
        const payload: PersonIdentificationCheckInput = {
          perId: validatePersonData?.perId || '',
          idType: _primaryIdType ? _primaryIdType : PersonPrimaryIdType.None,
          idNumber: verifyInfo?.idValue || '',
        };
        const result = await validateLastFourDigitPersonId(payload);
        if (!result && idVerificationFailureCount === 2) {
          setLoading(false);
          setInvalidLastFourDigitsError(false);
          void navigate(ROUTES.SSM_START_INELIGIBLE, {
            state: { errorType: 'verifyInfoError' },
          });
          return false;
        } else if (!result) {
          setLoading(false);
          setInvalidLastFourDigitsError(true);
          setIdVerificationFailureCount(prev => prev + 1);
          return false;
        }
      } else {
        const { idType } = verifyInfo;
        if (idType === PersonPrimaryIdType.Ssn) {
          const {
            addressAndDate: { address },
            contactInfo,
          } = ssmStartServiceState;
          const param: ExperianCreditCheckRequest = {
            firstName: contactInfo.firstName || '',
            middleName: contactInfo.middleName,
            lastName: contactInfo.lastName || '',
            encryptedPersonId: validatePersonData?.encryptedPersonId,
            currentAddress: {
              streetAddress: address?.addressLine1 || '',
              city: address?.city || '',
              postalCode: address?.postal || '',
              stateOrProvince: 'OR',
            },
            ssn: verifyInfo.idValue,
          };
          const result = await experianCreditCheck({ param });

          if (
            !result.loading &&
            !result.errors &&
            Object.keys(result.data).length > 0
          ) {
            setLoading(false);
            const payload: CustomerContactInput = {
              accountNumber: '',
              longDescription: '',
              encryptedPersonId: validatePersonData?.encryptedPersonId,
              contactClass: CustContactClass.WSS,
              contactType: CustContactType.WEBCK,
              characteristics: [
                {
                  type: CustContactCharacteristicType.CMCHNNL,
                  value: CustContactCharacteristicValue.CSWEB,
                  action: '',
                },
              ],
              shouldPrintLetter: false,
            };
            if (result.data?.experianCreditCheck?.hasError) {
              setErrorNotification(true);
              return false;
            } else if (result.data?.experianCreditCheck?.notFound) {
              if (isAuthenticated) {
                setLoading(true);
                const ccResult = await createWebCustomerContact({
                  ...payload,
                  longDescription:
                    'Experian credit check ineligible due to not found',
                });
                setLoading(false);
                if (ccResult?.errors) {
                  setErrorNotification(true);
                }
              }

              setVerifyInfo(verifyInfo);
              void navigate(ROUTES.SSM_START_INELIGIBLE, {
                state: { errorType: 'notFound' },
              });
              return false;
            } else if (result.data?.experianCreditCheck?.isFraud) {
              if (isAuthenticated) {
                setLoading(true);
                const ccResult = await createWebCustomerContact({
                  ...payload,
                  longDescription:
                    'START SERVICE: Experian credit check ineligible due to Fraud/Bad Credit.',
                });
                setLoading(false);
                if (ccResult?.errors) {
                  setErrorNotification(true);
                }
              }
              void navigate(ROUTES.SSM_START_INELIGIBLE, {
                state: { errorType: 'isFraud' },
              });
              return false;
            }
          }
        } else {
          setLoading(false);
        }
      }
    } catch {
      setLoading(false);
      setErrorNotification(true);
      return false;
    }
    setLoading(false);
    setVerifyInfo(verifyInfo);
    return true;
  };

  const handleHomeDetails = async (homeDetails: HomeDetails) => {
    setLoading(true);
    const { sameAsServiceAddress } = homeDetails;
    setHomeDetails(homeDetails);
    const _contactInfo = { ...ssmStartServiceState.contactInfo };
    if (_contactInfo.mobilePhone !== homeDetails.registeredMobileNumber) {
      _contactInfo.mobilePhone = homeDetails.registeredMobileNumber;
      setContactInfo(_contactInfo);
    }
    if (!sameAsServiceAddress) {
      try {
        const data = await doVerifyQASAddress(homeDetails);
        setQasData(state => ({
          ...state,
          results: data?.results,
          params: data?.params,
        }));
        if (data.results.type === 'AUTO') {
          setHomeDetails({ ...homeDetails, mailQasVerified: true });
          void navigate(ROUTES.SSM_START_CONFIRM);
          setLoading(false);
          return true;
        } else {
          setLoading(false);
          return false;
        }
      } catch {
        setLoading(false);
        setErrorNotification(true);
        return false;
      }
    } else {
      setLoading(false);
      setHomeDetails(homeDetails);
      return true;
    }
  };

  const handleConfirm = wrapWithLoader(async () => {
    const result = await onConfirm(
      isAuthenticated,
      metersInfo,
      personId,
      isExistingPerson,
      validatePersonData?.perId!,
      registeredCssUser,
    );
    setStartServiceSubmitResponse(result.data?.startSubmit);
    if (result?.errors) {
      setErrorNotification(true);
      return false;
    }
    if (
      result.data?.startSubmit?.isRegistrationSuccess &&
      result.data?.startSubmit?.signinToken
    ) {
      await signInWithCustomToken(result.data?.startSubmit?.signinToken);
    }
    return true;
  });

  useEffect(() => {
    if (pathname !== ROUTES.SSM_START_SUCCESS) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.SSM_START_ADDRESS, { replace: true });
    }
  }, []);

  const steps = [
    {
      path: ROUTES.SSM_START_ADDRESS,
      title: t('WHERE_DO_YOU_NEED_SERVICE'),
      wizardStep: (
        <WizardStep
          initial={ssmStartServiceState.addressAndDate}
          onNext={handleAddressAndDate}
          //TODO - update with correct route back to SSM marketing page when ready
          backRoute={ROUTES.HOME}
          backText={t('CANCEL')}
          component={SSMAddressAndDateForm}
          formAdapter={createAddressAndDateFormAdapter()}
          validate={createAddressAndDateValidateFunction()}
          componentProps={{
            isAddressVerified,
            setIsAddressVerified,
            isMeterSelectionVerified,
            setIsMeterSelectionVerified,
            isAddressSuggestionModalDisplayed,
            setIsAddressSuggestionModalDisplayed,
            autoCompleteAddress,
            setAutoCompleteAddress,
            isMultipleMetersModalDisplayed,
            setIsMultipleMetersModalDisplayed,
            metersInfo,
            setMetersInfo,
            currentPosition,
            setCurrentPosition,
            setPersonName,
            setMultiplePremisesIneligible,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('ADDRESS')} ${t('DETAILS').toLowerCase()}`,
        isComplete:
          !!ssmStartServiceState?.addressAndDate?.address &&
          !!ssmStartServiceState?.addressAndDate?.date,
      },
    },
    {
      path: ROUTES.SSM_START_CONTACT,
      title: inlineRichT('SSM_CONTACT_INFO_FORM_TITLE'),
      wizardStep: (
        <WizardStep
          initial={ssmStartServiceState.contactInfo}
          onNext={handleContactInfo}
          component={SSMContactInfoForm}
          onBack={concatenateAddressFields}
          formAdapter={createContactInfoFormAdapter()}
          validate={createContactInfoValidateFunction()}
          componentProps={{
            isExistingEmail,
            setIsExistingEmail,
            isCustomerFoundModalDisplayed,
            setIsCustomerFoundModalDisplayed,
            validatePersonData,
            loginEmail,
            setLoginEmail,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('CONTACT')} ${t('INFORMATION').toLowerCase()}`,
        isComplete: !!ssmStartServiceState.contactInfo?.firstName,
      },
    },
    {
      path: ROUTES.SSM_START_VERIFY,
      title: isAuthenticated
        ? t('HELP_US_VERIFY_WHO_YOU_ARE')
        : inlineRichT('SSM_VERIFY_FORM_TITLE'),
      wizardStep: (
        <WizardStep
          initial={ssmStartServiceState.verifyInfo}
          formAdapter={createSSMVerifyInfoFormAdapter()}
          validate={createVerifyInfoValidateFunction(
            validatePersonData?.primaryIdType,
          )}
          onNext={handleVerifyInfo}
          component={SSMVerifyInfoForm}
          backRoute={isAuthenticated ? ROUTES.SSM_START_ADDRESS : undefined}
          componentProps={{
            setContactInfo,
            primaryIdType: validatePersonData?.primaryIdType,
            invalidLastFourDigitsError,
            setInvalidLastFourDigitsError,
          }}
          onBack={() => {
            if (isAuthenticated) {
              concatenateAddressFields();
            }
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('VERIFY')} ${t('INFORMATION').toLowerCase()}`,
        isComplete: !!ssmStartServiceState.verifyInfo?.idValue,
      },
    },
    {
      path: ROUTES.SSM_START_HOME,
      title: t('TELL_US_ABOUT_YOUR_HOME'),
      wizardStep: (
        <WizardStep
          initial={{
            ...ssmStartServiceState.homeDetails,
            registeredMobileNumber: ssmStartServiceState.contactInfo.mobilePhone?.replace(
              /[^0-9]/g,
              '',
            ),
          }}
          onNext={handleHomeDetails}
          component={SSMHomeDetailsForm}
          formAdapter={createSSMMoveHomeDetailsFormAdapter()}
          validate={createHomeDetailsValidateFunction()}
          componentProps={{
            qasData,
            setQasData,
            isSSMStart: true,
            customerEmail: ssmStartServiceState.contactInfo.email,
            startServiceAddress: ssmStartServiceState.addressAndDate.address,
            mobNumber: ssmStartServiceState.contactInfo.mobilePhone,
            setHomeDetails,
            setIsRenewablesEligible,
            isRenewablesEligible: true,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('HOME')} ${t('DETAILS').toLowerCase()}`,
        isComplete: !!ssmStartServiceState.homeDetails?.propertyUsage,
      },
    },
    {
      path: ROUTES.SSM_START_CONFIRM,
      title: t('EVERYTHING_LOOK_CORRECT'),
      wizardStep: (
        <WizardStep
          initial={ssmStartServiceState}
          nextText={t('SUBMIT')}
          onNext={handleConfirm}
          nextRoute={ROUTES.SSM_START_SUCCESS}
          proceedDisabled={startServiceSubmitResponse?.isStartSuccess || false}
          component={SSMConfirmDetails}
          componentProps={{
            metersInfo,
            ssmStartServiceState,
            concatenateAddressFields,
            isRenewablesEligible,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('CONFIRM')} ${t('DETAILS').toLowerCase()}`,
        isComplete: false,
      },
    },
  ];

  return validateAccountLoading ? (
    <Backdrop forceOpen message={t('LOADING')} />
  ) : (
    <>
      {customerInfoLoading || loading ? (
        <Backdrop forceOpen message={t('LOADING')} />
      ) : null}
      {pathname !== ROUTES.SSM_START_SUCCESS ? (
        <Hidden smUp>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.mobileSummary}
          >
            <Grid item container>
              <SSMServiceSummary
                summary={serviceSummary}
                isPulldown={true}
                service="START"
              />
            </Grid>
          </Grid>
        </Hidden>
      ) : null}
      {pathname &&
      ![ROUTES.SSM_START_SUCCESS, ROUTES.SSM_START_INELIGIBLE].includes(
        pathname,
      ) ? (
        <Container maxWidth="lg" className={classes.root}>
          <Page2ColumnLayout
            leftColumn={<SSMServiceSummary summary={serviceSummary} />}
          >
            <Grid
              item
              direction="column"
              spacing={2}
              xs={12}
              justify={'center'}
              alignItems={'center'}
              className={classes.containerWidth}
            >
              <Grid
                item
                container
                direction="column"
                xs={12}
                justify={'center'}
                alignItems={'center'}
              >
                <Hidden smDown>
                  <Grid item>
                    <Typography variant={'h1'} align={'center'}>
                      {steps.find(s => s.path === pathname)?.title ?? ''}
                    </Typography>
                  </Grid>
                </Hidden>

                <Grid item container justify="center" xs={12}>
                  <ProgressTracker
                    steps={steps.map(({ progressStep, path }, index) => ({
                      ...progressStep,
                      isActive: path === pathname,
                      isComplete:
                        progressStep.isComplete ||
                        steps.map(step => step.path).indexOf(pathname || '') >
                          index,
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  justify="center"
                  xs={12}
                  className={classes.fullWidth}
                >
                  <Wizard basepath="/">
                    {steps.map(({ wizardStep, path, title }, index) => {
                      return (
                        <wizardStep.type
                          {...{
                            ...wizardStep.props,
                            path: path,
                            key: path,
                            title,
                            backRoute:
                              wizardStep.props.backRoute ??
                              (index > 0 ? steps[index - 1].path : false),
                            nextRoute:
                              wizardStep.props.nextRoute ??
                              (index < steps.length - 1
                                ? steps[index + 1].path
                                : undefined),
                          }}
                        />
                      );
                    })}
                  </Wizard>
                </Grid>
              </Grid>
            </Grid>
          </Page2ColumnLayout>
        </Container>
      ) : (
        <Container maxWidth="lg">
          <Page2ColumnLayout
            leftColumn={
              pathname !== ROUTES.SSM_START_SUCCESS ? (
                <SSMServiceSummary summary={serviceSummary} />
              ) : (
                undefined
              )
            }
          >
            <Grid
              item
              direction="column"
              spacing={2}
              xs={12}
              justify={'center'}
              alignItems={'center'}
              className={
                pathname !== ROUTES.SSM_START_SUCCESS
                  ? validatePremiseData &&
                    validatePremiseData?.validatePremise[0]?.eligibility !==
                      StartMoveEligibility.InEligible
                    ? classes.containerWidth
                    : undefined
                  : undefined
              }
            >
              <Grid
                item
                container
                direction="column"
                xs={12}
                justify={'center'}
                alignItems={'center'}
              >
                <Grid
                  item
                  justify="center"
                  xs={12}
                  className={classes.fullWidth}
                >
                  <Router basepath="/">
                    <SSMStartServiceSuccess
                      path={ROUTES.SSM_START_SUCCESS}
                      ssmStartServiceState={ssmStartServiceState}
                      startServiceSubmitResponse={startServiceSubmitResponse}
                      customerInfoData={customerInfoData}
                    />
                    <SSMStartServiceIneligible
                      path={ROUTES.SSM_START_INELIGIBLE}
                    />
                  </Router>
                </Grid>
              </Grid>
            </Grid>
          </Page2ColumnLayout>
        </Container>
      )}
      <PremiseEligibility
        isOpen={isPremiseEligibilityModalDisplayed}
        handleClose={() => setIsPremiseEligibilityModalDisplayed(false)}
        userAddress={autoCompleteAddress || ''}
        eligibilityInfo={eligibilityInfo}
      />
    </>
  );
};

export default SSMStartService;
