import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core';
import { useTranslation } from '../../../../hooks/useTranslation';
import Modal from '../../../modal';
import Button from '../../../buttons';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';
import ROUTES from '../../../../routes';
import useAccountForm from '../../../../hooks/useAccountForm';
import { useIsMobile } from '../../../../util/style-utils';
import EmailField from '../../../../components/email-text-field';
import PasswordField from '../../../../components/password-text-field';
import Link from '../../../../components/text-link';
import Tooltip from '../../../../components/tooltip';
import { navigate } from 'gatsby';

type CustomerFoundProps = {
  isOpen: boolean;
  handleClose: () => void;
  openCSSWebUserModal: () => void;
  createOnlineAccount: boolean;
  loginEmail: string | undefined;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '32px',
      color: colors.noirBlur,
      marginBottom: '6px',
      textAlign: 'center',
    },
    container: {
      maxWidth: '560px',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '70vh',
        overflowY: 'auto',
      },
    },
    description: {
      display: 'flex',
      justifyContent: 'center',
    },
    descriptionText: {
      fontSize: '20px',
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      textAlign: 'center',
      marginBottom: '10px',
    },
    body: {
      padding: '10px 25px 10px 25px',
      borderRadius: '4px',
      maxWidth: '400px',
    },
    marginBottom: {
      marginBottom: '6px',
    },
    actions: {
      display: 'flex',
      marginTop: '16px',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    back: {
      fontSize: '18px',
      padding: '5px 25px 5px 25px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    okay: {
      fontSize: '18px',
      color: colors.white,
      padding: '5px 25px 5px 25px',
      marginLeft: '20px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 'unset',
        marginTop: '16px',
      },
    },
    tooltip: {
      maxWidth: '400px',
      borderRadius: 0,
      boxShadow: `-6px 5px 6px ${colors.tooltipBoxShadow}`,
      background: colors.white,
      fontSize: '16px',
    },
  }),
);

export const CustomerFound = (props: CustomerFoundProps) => {
  const {
    isOpen,
    handleClose,
    openCSSWebUserModal,
    createOnlineAccount,
    loginEmail,
  } = props;
  const { t, inlineRichT } = useTranslation();
  const isMobile = useIsMobile();
  const { form, handleSubmit, handleForgotPassword } = useAccountForm({
    redirectUrl: ROUTES.SSM_START_VERIFY,
  });
  const classes = useStyles();

  const handleContinueAsGuest = () => {
    handleClose();
    if (!createOnlineAccount) {
      openCSSWebUserModal();
    } else {
      void navigate(ROUTES.SSM_START_VERIFY);
    }
  };

  useEffect(() => {
    if (loginEmail) {
      void form.setValue('email', loginEmail);
    }
  }, [openCSSWebUserModal]);

  return (
    <Modal
      open={isOpen}
      showCloseIcon={true}
      onClose={handleClose}
      title={
        <Typography
          variant="body1"
          className={classes.title}
          data-testid="modal-title"
        >
          {t('EXISTING_CUSTOMER_FOUND')}
        </Typography>
      }
    >
      <Grid container className={classes.container}>
        <Grid container className={classes.description}>
          <Typography
            className={classes.descriptionText}
            data-testid="modal-header-text"
          >
            {inlineRichT('SSM_CUSTOMER_FOUND_HEADER')}
          </Typography>
        </Grid>

        <Grid container className={classes.description}>
          <Typography
            className={classes.descriptionText}
            data-testid="modal-description-text"
          >
            {inlineRichT('LOGIN_TO_CONTINUE')}
          </Typography>
        </Grid>
        <Grid container className={classes.body}>
          <Grid container item>
            <EmailField
              autoFocus
              autoComplete="username"
              inputProps={{ maxLength: 50 }}
              data-testid="email"
              {...form.props('email')}
            />
          </Grid>
          <Grid container item>
            <PasswordField
              defaultShow={isMobile}
              autoComplete="current-password"
              data-testid="password"
              {...form.props('password')}
            />
          </Grid>
          <Grid container item>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={form.onChange}
                  checked={form.values.rememberMe}
                  name={'rememberMe'}
                  data-testid="rememberMe"
                  color={'primary'}
                />
              }
              label={t('REMEMBER_ME')}
            />
          </Grid>
          <Grid container item data-testid="forgotPassword">
            <Link
              plain
              to={ROUTES.FORGOT_PASSWORD}
              variant={'body2'}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                handleForgotPassword();
              }}
            >
              {t('FORGOT_PASSWORD')}
            </Link>
          </Grid>
          <Grid container item data-testid="securityInfo">
            <Tooltip
              title={t('SIGN_IN_SECURITY_INFO_TOOL_TIP')}
              classes={{ tooltip: classes.tooltip }}
            >
              <Box>
                <Link
                  tooltip
                  style={{ fontSize: '16px' }}
                  to="#"
                  external
                  variant={'body2'}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    window.open(ROUTES.PRIVACY_POLICY, '_blank');
                  }}
                >
                  {t('SIGN_IN_SECURITY_INFO')}
                </Link>
              </Box>
            </Tooltip>
          </Grid>
          <Grid container item className={classes.actions}>
            <Button
              color={'secondary'}
              variant={'outlined'}
              className={classes.back}
              onClick={handleContinueAsGuest}
              data-testid="modal-continue-as-guest-button"
            >
              {t('CONTINUE_AS_GUEST')}
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              className={classes.okay}
              onClick={handleSubmit}
              data-testid="modal-sign-in-button"
            >
              {t('SIGN_IN_1')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};
