import { Grid, makeStyles, createStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import { convertValidationRules } from '../../../hooks/useFormState';
import { ContactInfo, VerifyInfo } from '../../../hooks/useSSMStartService';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  validateNameField,
  validateNameFieldStrictCasing,
} from '../../../util/form-validation';
import { Maybe, PersonPrimaryIdType } from '../../../__generated__/pge-types';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import {
  idTypeValueLastFourDigitsValidators,
  primaryCustomerFourDigitsValidator,
  idTypeValueValidators,
  validateCustomerBirthdate,
} from '../post-enrollment/CoCustomer/AddCoCustomer/AddCoCustomerForm.rules';
import SSMCustomerVerification from './SSMCustomerVerification';
import Backdrop from '../../backdrop';
import useUtil from '../../need-more-time-to-pay/useUtil';
import { idTypeMap } from './IdentityVerification/VerifyExistingCustomerIdentity';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '16px',
      marginBottom: '16px',
    },
    marginBottom: {
      marginBottom: '16px',
    },
    subText: {
      textAlign: 'center',
      marginBottom: '40px',
    },
  }),
);

export const SSMVerifyInfoForm = ({
  formState,
  componentProps,
}: WizardFormProps<VerifyInfo>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const {
    setContactInfo,
    primaryIdType,
    invalidLastFourDigitsError,
    setInvalidLastFourDigitsError,
  } = componentProps;
  const { setErrorNotification } = useUtil();
  const {
    customer: customerInfoData,
    loading: customerInfoLoading,
    error: customerInfoError,
  } = useAccountCustomer();

  const getIdTypeLabel = (): string => {
    return t(idTypeMap[primaryIdType] || '');
  };

  useEffect(() => {
    if (customerInfoError) {
      setErrorNotification(true);
    } else {
      setErrorNotification(false);
    }
  }, [customerInfoError]);

  useEffect(() => {
    if (invalidLastFourDigitsError) {
      formState.setError(
        'idValue',
        `${t('ENTER_THE_LAST_FOUR_DIGITS_OF_YOUR')} ${getIdTypeLabel()}.`,
      );
      setInvalidLastFourDigitsError(false);
    }
  }, [invalidLastFourDigitsError]);

  useEffect(() => {
    if (customerInfoData) {
      const contactInfo: ContactInfo = {};
      const fullName = customerInfoData.personName;
      contactInfo.firstName = fullName?.split(', ')[1]?.split(' ')[0];
      contactInfo.middleName = fullName?.split(', ')[1]?.split(' ')[1];
      contactInfo.lastName = fullName?.split(', ')[0];
      const { contactDetails } = customerInfoData;
      contactDetails?.forEach(contactItem => {
        if (contactItem?.contactType === 'EMAIL') {
          contactInfo.email = contactItem.contactValue;
        }
        if (contactItem?.contactType === 'PNP') {
          contactInfo.primaryPhone = contactItem.contactValue;
        }
        if (contactItem?.contactType === 'MOB') {
          contactInfo.mobilePhone = contactItem.contactValue;
        }
        if (contactItem?.contactType === 'ALT') {
          contactInfo.altPhone = contactItem.contactValue;
        }
      });
      setContactInfo(contactInfo);
    }
  }, [customerInfoData]);

  return customerInfoLoading ? (
    <Backdrop forceOpen message={t('LOADING')} />
  ) : (
    <Grid container direction="column" className={classes.container}>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.subText}
      >
        {!isAuthenticated ? (
          <Typography>{t('CREDIT_CHECK_INFO')}</Typography>
        ) : null}
      </Grid>
      <SSMCustomerVerification
        form={formState}
        primaryIdType={primaryIdType}
        isCoCustomer={false}
      />
    </Grid>
  );
};

export const createSSMVerifyInfoFormAdapter = () => {
  return {
    toFormState: (initial: VerifyInfo) => ({
      birthdate: initial.birthdate,
      idType: initial.idType,
      idState: initial.idState,
      idValue: initial.idValue,
    }),
    fromFormState: (state: VerifyInfo) => {
      return {
        birthdate: state.birthdate,
        idType: state.idType,
        idState: state.idState,
        idValue: state.idValue,
      };
    },
  };
};

export const createVerifyInfoValidateFunction = (
  primaryIdType?: Maybe<PersonPrimaryIdType> | undefined,
) =>
  convertValidationRules<VerifyInfo>(context => {
    const { values } = context;
    const { t } = useTranslation();
    const showLastFourDigits =
      primaryIdType && primaryIdType !== PersonPrimaryIdType.None;
    return {
      birthdate: showLastFourDigits ? () => null : validateCustomerBirthdate,
      idType: showLastFourDigits
        ? () => null
        : validateNameFieldStrictCasing.bind(null, t('ID_TYPE')),
      idState: showLastFourDigits
        ? () => null
        : values.idType === PersonPrimaryIdType.Dl
        ? validateNameField.bind(null, t('STATE'))
        : null,
      idValue: showLastFourDigits
        ? primaryCustomerFourDigitsValidator[values.idType]?.()
        : idTypeValueValidators[values.idType]?.(),
    };
  });
