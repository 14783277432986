import { useState } from 'react';
import {
  PersonPrimaryIdType,
  PropertyUsage,
  QuickAddressSearchParams,
  ServiceAddress,
  SsmCustomerInfo,
  StartSubmitMailingAddress,
  StartSubmitPremise,
  StartSubmitRequest,
} from '../__generated__/pge-types';
import {
  Meter,
  HomeDetails,
  PropertyUsageType,
} from '../components/ssm/ssm.types';
import { useSSMStartServiceSubmit } from './useSSMStartServiceSubmit';
import { displayPhoneNumberFormat, getPostalCountry } from '../util/format';
import moment from 'moment';
import useQas from './useQas';

export type AddressAndDate = {
  address?: ServiceAddress;
  date?: Date;
};

export type VerifyInfo = {
  idType:
    | PersonPrimaryIdType.Ssn
    | PersonPrimaryIdType.Dl
    | PersonPrimaryIdType.Matr
    | PersonPrimaryIdType.Pssprt
    | PersonPrimaryIdType.Resalien
    | PersonPrimaryIdType.Visa
    | PersonPrimaryIdType.Military
    | PersonPrimaryIdType.Ortrib
    | PersonPrimaryIdType.Itin;
  idState: string;
  idValue: string;
  birthdate: string;
};

export type ContactInfo = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  primaryPhone?: string;
  useForMobile?: boolean;
  mobilePhone?: string;
  altPhone?: string;
  email?: string;
  createOnlineAccount?: boolean;
  password?: string;
};

export type SSMStartServiceState = {
  addressAndDate: AddressAndDate;
  contactInfo: ContactInfo;
  verifyInfo: VerifyInfo;
  homeDetails: HomeDetails;
};

export default () => {
  const { search } = useQas();
  const { submit } = useSSMStartServiceSubmit();
  const [ssmStartServiceState, setSSMStartServiceState] = useState<
    SSMStartServiceState
  >({
    addressAndDate: {},
    contactInfo: {},
    verifyInfo: {
      birthdate: '',
      idType: PersonPrimaryIdType.Ssn,
      idState: '',
      idValue: '',
    },
    homeDetails: {},
  });

  const setAddressAndDate = (addressAndDate: AddressAndDate) => {
    const { address, date } = addressAndDate;
    const addressLine1 = address?.addressLine1?.split(',')[0].trim();
    const city = address?.addressLine1?.split(',')[1].trim();
    const postal = address?.addressLine1?.split(',')[2].trim();
    const formattedAddressAndDate = {
      address: {
        addressLine1,
        city,
        postal,
      },
      date,
    };
    setSSMStartServiceState(state => ({
      ...state,
      addressAndDate: formattedAddressAndDate,
    }));
  };

  const concatenateAddressFields = () => {
    setSSMStartServiceState(state => ({
      ...state,
      addressAndDate: {
        address: {
          addressLine1: `${
            state?.addressAndDate?.address?.addressLine1
              ? state?.addressAndDate?.address?.addressLine1 + ','
              : ''
          } ${
            state?.addressAndDate?.address?.city
              ? state?.addressAndDate?.address?.city + ','
              : ''
          } ${
            state?.addressAndDate?.address?.postal
              ? state?.addressAndDate?.address?.postal
              : ''
          }`.trim(),
          city: '',
          postal: '',
        },
        date: state.addressAndDate.date,
      },
    }));
  };

  const doVerifyAddress = (
    isAddressVerified: boolean,
    autoCompleteAddress: string,
    setIsAddressSuggestionModalDisplayed: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setIsMultipleMetersModalDisplayed: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    metersInfo: Meter[],
    isMeterSelectionVerified: boolean,
    _address?: ServiceAddress,
  ) => {
    if (metersInfo.length > 0) {
      const isEveryMeterDisabled = metersInfo.every(
        meter => meter.disabled === true,
      );
      if (isEveryMeterDisabled) {
        setIsMultipleMetersModalDisplayed(false);
        return true;
      }
    }
    if (isAddressVerified && _address?.addressLine1 !== autoCompleteAddress) {
      setIsAddressSuggestionModalDisplayed(true);
      return false;
    }
    if (!isAddressVerified) {
      setIsAddressSuggestionModalDisplayed(true);
      return false;
    }
    if (metersInfo.length > 10) {
      setIsMultipleMetersModalDisplayed(true);
      return false;
    }
    if (
      metersInfo.length > 1 &&
      metersInfo.length < 11 &&
      !isMeterSelectionVerified
    ) {
      setIsMultipleMetersModalDisplayed(true);
      return false;
    }
    if (!isMeterSelectionVerified) {
      return false;
    }
    return true;
  };

  const setContactInfo = (contactInfo: ContactInfo) => {
    setSSMStartServiceState(state => ({
      ...state,
      contactInfo,
    }));
  };

  const setVerifyInfo = (verifyInfo: VerifyInfo) => {
    setSSMStartServiceState(state => ({
      ...state,
      verifyInfo,
    }));
  };

  const doVerifyInfo = (_verifyInfo?: VerifyInfo) => true;

  const setHomeDetails = (homeDetails: HomeDetails) => {
    setSSMStartServiceState(state => ({
      ...state,
      homeDetails,
    }));
  };

  const getPropertyUsage = (usageType: PropertyUsageType | undefined) => {
    if (usageType === PropertyUsageType.Own) {
      return PropertyUsage.Owner;
    } else if (usageType === PropertyUsageType.TNT) {
      return PropertyUsage.Tenant;
    } else if (usageType === PropertyUsageType.LLD) {
      return PropertyUsage.Landlord;
    } else if (usageType === PropertyUsageType.FNF) {
      return PropertyUsage.FriendFamily;
    } else {
      return PropertyUsage.Owner;
    }
  };

  function formatSocialSecurity(ssn: string) {
    let val = ssn;
    val = val.replace(/\D/g, '');
    val = val.replace(/^(\d{3})/, '$1-');
    val = val.replace(/-(\d{2})/, '-$1-');
    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
    return val;
  }

  const getPremises = (metersInfo: Meter[]) => {
    const premises: StartSubmitPremise[] = [];
    metersInfo.forEach((meter: Meter) => {
      let item: StartSubmitPremise;
      const index = premises.findIndex(
        payloadItem => payloadItem?.premId === meter.premiseId,
      );
      if (index === -1) {
        item = {
          premId: meter.premiseId,
          servicePointList: [{ spId: meter.spId }],
        };
        if (meter.checked) {
          premises.push(item);
        }
      } else {
        item = {
          premId: meter.premiseId,
          servicePointList: [
            ...premises[index].servicePointList,
            { spId: meter.spId },
          ],
        };
        if (meter.checked) {
          premises[index] = item;
        }
      }
    });
    return premises;
  };

  const getCustomerInfo = (
    isAuthenticated: boolean,
    personId: string,
    isExistingPerson: boolean,
    validatePersonId: string | undefined,
  ) => {
    const mailingAddress: StartSubmitMailingAddress = {
      addressLine1: ssmStartServiceState.homeDetails.mailAddress ?? '',
      city: ssmStartServiceState.homeDetails.mailCity ?? '',
      state: ssmStartServiceState.homeDetails.mailState ?? '',
      postal: ssmStartServiceState.homeDetails.mailZip ?? '',
      country: ssmStartServiceState.homeDetails.mailCountry ?? '',
      qasVerified: ssmStartServiceState.homeDetails.mailQasVerified ?? false,
      inCareOf: ssmStartServiceState.homeDetails.inCareOf ?? '',
    };
    const item: SsmCustomerInfo = {
      lastName: '',
      firstName: '',
      additionalInfo: {
        primaryPhone: displayPhoneNumberFormat(
          ssmStartServiceState.contactInfo.primaryPhone?.replace(/[^0-9]/g, ''),
        ),
        isPrimaryPhoneMobile:
          ssmStartServiceState.contactInfo.useForMobile ?? false,
        emailAddress: ssmStartServiceState.contactInfo.email ?? '',
        idInfo: {
          idType: ssmStartServiceState.verifyInfo.idType,
          idValue: ((ssmStartServiceState.verifyInfo.idType ===
            PersonPrimaryIdType.Ssn ||
            ssmStartServiceState.verifyInfo.idType ===
              PersonPrimaryIdType.Itin) &&
          ssmStartServiceState.verifyInfo.idValue.length !== 4
            ? formatSocialSecurity(ssmStartServiceState.verifyInfo.idValue)
            : ssmStartServiceState.verifyInfo.idValue
          )?.trim(),
          state: ssmStartServiceState.verifyInfo.idState,
        },
      },
    };
    if (ssmStartServiceState.verifyInfo.birthdate) {
      item.additionalInfo!.dateOfBirth = moment(
        ssmStartServiceState.verifyInfo.birthdate,
      ).format('YYYY-MM-DD');
    }
    item.additionalInfo!.mailingAddress = ssmStartServiceState.homeDetails
      .sameAsServiceAddress
      ? undefined
      : mailingAddress;
    if (ssmStartServiceState.contactInfo.mobilePhone) {
      item.additionalInfo!.mobPhone = displayPhoneNumberFormat(
        ssmStartServiceState.contactInfo.mobilePhone?.replace(/[^0-9]/g, ''),
      );
    }
    if (ssmStartServiceState.contactInfo.useForMobile) {
      item.additionalInfo!.mobPhone = displayPhoneNumberFormat(
        ssmStartServiceState.contactInfo.primaryPhone?.replace(/[^0-9]/g, ''),
      );
    }
    if (ssmStartServiceState.contactInfo.altPhone) {
      item.additionalInfo!.alternatePhone = displayPhoneNumberFormat(
        ssmStartServiceState.contactInfo.altPhone?.replace(/[^0-9]/g, ''),
      );
    }
    if (isAuthenticated) {
      item.perId = personId;
    } else {
      item.firstName = ssmStartServiceState.contactInfo.firstName?.trim() ?? '';
      item.lastName = ssmStartServiceState.contactInfo.lastName?.trim() ?? '';
      item.middleName =
        ssmStartServiceState.contactInfo.middleName?.trim() ?? '';
    }

    if (!isAuthenticated && isExistingPerson && validatePersonId) {
      item.perId = validatePersonId;
    }
    return item;
  };

  const onConfirm = async (
    isAuthenticated: boolean,
    metersInfo: Meter[],
    personId: string,
    isExistingPerson: boolean,
    validatePersonId: string | undefined,
    registeredCssUser: string | undefined,
  ) => {
    const isGuest = isAuthenticated ? false : isExistingPerson ? true : false;
    const payload: StartSubmitRequest = {
      isUserLoggedIn: isAuthenticated,
      isGuest,
      enrollInPaperless: ssmStartServiceState.homeDetails.goPaperLess ?? false,
      mailingAndServiceAddressesSame:
        ssmStartServiceState.homeDetails.sameAsServiceAddress ?? false,
      receiveNotifications:
        ssmStartServiceState.homeDetails.shouldReceiveNotification ?? false,
      registerToPortal:
        ssmStartServiceState.contactInfo.createOnlineAccount ?? false,
      submitDate: moment(new Date()).format('YYYY-MM-DD'),
      startDate: moment(ssmStartServiceState.addressAndDate.date).format(
        'YYYY-MM-DD',
      ),
      propertyUsage: getPropertyUsage(
        ssmStartServiceState.homeDetails.propertyUsage,
      ),
      greenSource:
        ssmStartServiceState.homeDetails.enrollInGreenFuture ?? false,
      premises: getPremises(metersInfo),
      customerInfo: getCustomerInfo(
        isAuthenticated,
        personId,
        isExistingPerson,
        validatePersonId,
      ),
      serviceAddress: {
        addressLine1: ssmStartServiceState.addressAndDate.address?.addressLine1,
        city: ssmStartServiceState.addressAndDate.address?.city,
        postal: ssmStartServiceState.addressAndDate.address?.postal,
        qasVerified: ssmStartServiceState.homeDetails.mailQasVerified,
        country: 'USA',
        state: 'OR',
      },
    };
    if (ssmStartServiceState.homeDetails.shouldReceiveNotification) {
      payload.notificationPhone = displayPhoneNumberFormat(
        ssmStartServiceState.homeDetails.registeredMobileNumber?.replace(
          /[^0-9]/g,
          '',
        ),
      );
    }
    if (ssmStartServiceState.contactInfo.createOnlineAccount) {
      payload.customerInfo!.additionalInfo!.password =
        ssmStartServiceState.contactInfo.password;
    }
    if (registeredCssUser) {
      payload.registeredCssUser = registeredCssUser;
    }

    const result = await submit(payload);
    return result;
  };

  const doVerifyQASAddress = async (homeDetails: HomeDetails) => {
    const { mailAddress, mailCity, mailState, mailZip } = homeDetails;
    const params: QuickAddressSearchParams = {
      city: mailCity,
      state: mailState,
      addressLine1: mailAddress,
      postal: mailZip,
      isMailingAddress: true,
      country: mailZip ? getPostalCountry(mailZip) : '',
    };
    const results = await search(params);
    return { results, params };
  };

  return {
    ssmStartServiceState,
    setAddressAndDate,
    doVerifyAddress,
    concatenateAddressFields,
    setContactInfo,
    setVerifyInfo,
    doVerifyInfo,
    setHomeDetails,
    onConfirm,
    doVerifyQASAddress,
  };
};
