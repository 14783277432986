import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  StartSubmitRequest,
  StartSubmitResponse,
} from '../__generated__/pge-types';

const START_SERVICE_SUBMIT = gql`
  mutation StartSubmit($payload: StartSubmitRequest) {
    startSubmit(payload: $payload) {
      acctId
      perId
      isStartSuccess
      isRegistrationSuccess
      signinToken
      encryptedAccountNumber
    }
  }
`;

export function useSSMStartServiceSubmit() {
  const apolloClient = useApolloClient();
  async function submit(payload: StartSubmitRequest) {
    const result = await apolloClient.mutate<{
      startSubmit: StartSubmitResponse;
    }>({
      mutation: START_SERVICE_SUBMIT,
      variables: {
        payload,
      },
      refetchQueries: ['getAccountInfo']
    });
    return result;
  }

  return { submit };
}
