import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Hidden,
} from '@material-ui/core';
import { format } from 'date-fns';
import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { SSMStartServiceState } from '../../../hooks/useSSMStartService';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { displayPhoneNumberFormat } from '../../../util/format';
import { useIsMobile } from '../../../util/style-utils';
import {
  AccountCustomer,
  PersonPrimaryIdType,
  StartSubmitResponse,
} from '../../../__generated__/pge-types';
import ROUTES from '../../../routes';
import ChangeDueDate from '../../../static/ChangeDueDate.svg';
import SetupAutoPay from '../../../static/SetupAutoPay.svg';
import ChangePreferences from '../../../static/ChangePreferences.svg';
import useAuth from '../../../hooks/useAuth';
import TextField from '../../text-field';
import useResidentialForm from '../../../hooks/useResidentialForm';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { RegistrationFailedModal } from './RegistrationFailedModal/RegistrationFailedModal';
import useSelectedAccountParams, {
  AccountIdentifier,
} from '../../../hooks/useSelectedAccountParams';
import PasswordIndicatorList from '../../password-indicator-list';

type Props = {
  path?: string;
  ssmStartServiceState: SSMStartServiceState;
  startServiceSubmitResponse: StartSubmitResponse | undefined;
  customerInfoData: AccountCustomer | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '16px',
      marginBottom: '16px',
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        backgroundColor: colors.modalBlue,
      },
      '& .MuiFormHelperText-contained': {
        backgroundColor: colors.modalBlue,
        margin: 0,
        padding: '0 14px',
      },
    },
    subText1: {
      textAlign: 'center',
      fontSize: '36px',
      fontWeight: 'bold',
    },
    subText2: {
      textAlign: 'center',
      fontSize: '20px',
      '& a': {
        wordBreak: 'break-all',
      },
    },
    subText3: {
      textAlign: 'center',
      fontSize: '28px',
      marginBottom: '30px',
    },
    subText4: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginTop: '30px',
    },
    subText5: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '30px',
    },
    subText6: {
      paddingRight: '10%',
      paddingLeft: '10%',
      marginTop: '10px',
    },
    desc: {
      marginTop: '10px',
      fontSize: '20px',
      fontWeight: 'bold',
      '& strong': {
        wordBreak: 'break-all',
      },
    },
    createAccount: {
      textAlign: 'center',
    },
    password: {
      paddingRight: '20%',
      paddingLeft: '20%',
      marginTop: '10px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '5%',
        paddingLeft: '5%',
      },
    },
    summaryCard: {
      padding: '20px',
      background: colors.siteBackground,
      border: `1px solid ${colors.brightBlue}`,
      borderRadius: '13px',
      boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
      width: '100%',
    },
    stepperIcon: {
      padding: '10px 0 20px',
    },
    completed: {
      color: `${colors.shoreGreen} !important`,
    },
    cardText: {
      fontSize: '16px',
      textAlign: 'center',
    },
    cardFirstline: {
      borderBottom: `1px solid ${colors.lightGray3}`,
      paddingBottom: '20px',
      marginBottom: '25px',
    },
    infoBox: {
      color: 'red',
    },
    headingGrid: {
      marginTop: '25px',
      marginBottom: '15px',
      textAlign: 'center',
      color: colors.brightBlue,
      fontSize: '40px',
    },
    subHeading: {
      fontSize: '20px',
      lineHeight: '30px',
      textAlign: 'center',
      marginBottom: '40px',
    },
    costSummmaryBox: {
      padding: '15px',
      background: colors.riverBlue,
    },
    costSummmaryBoxText: {
      fontSize: '18px',
    },
    belowInfo: {
      color: colors.charcoal,
      fontSize: '18px',
      margin: '6px 0 10px',
    },
    cardListingBox: {
      textAlign: 'left',
    },
    linkContainer: {
      marginLeft: 'auto',
    },
    listingItem: {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '17px',
      marginBottom: '17px',
      display: 'flex',
      flexDirection: 'row',
      '& span': {
        marginRihgt: '25px',
        display: 'inline-block',
        textAlign: 'left',
        fontWeight: '500',
      },
    },
    cardInnerBox: {
      marginTop: '10px',
      marginLeft: '18px',
    },
    addresSection: {
      '& span': {
        minWidth: '122px',
      },
    },
    cardInnerText: {
      fontSize: '14px',
      lineHeight: '17px',
      marginBottom: '10px',
    },
    topTest: {
      display: 'none',
    },
    doneButton: {
      textAlign: 'center',
      marginTop: '20px',
      '& button': {
        minWidth: '181px',
        fontSize: '18px',
      },
    },
    accountPref: {
      padding: '20px',
      background: colors.modalBackgroundColor,
      boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
      width: '100%',
      marginTop: '25px',
    },
    cards: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    card: {
      marginRight: '10px',
      padding: '10px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        marginTop: '15px',
        marginBottom: '15px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '33%',
      },
    },
    cardTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    cardDesc: {
      borderRight: `3px solid ${colors.white}`,
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
      },
    },
    cardIcon: {
      marginBottom: '10px',
    },
    cardMobileDivider: {
      borderTop: `3px solid ${colors.white}`,
      width: '90%',
    },
    email: {
      wordBreak: 'break-all',
    },
    passwordIndicatorList: {
      width: 'fit-content',
      margin: '0 auto',
    },
  }),
);

export const SSMStartServiceSuccess = (props: Props) => {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { isAuthenticated } = useAuth();
  const { wrapWithLoader } = useWrapWithLoader();
  const {
    ssmStartServiceState,
    startServiceSubmitResponse,
    customerInfoData,
  } = props;
  const form = useResidentialForm(
    undefined,
    ROUTES.SSM_POST_ENROLLMENT_PAYMENT_PREFERENCES,
  );
  const [
    displayRegistrationFailedModal,
    setDisplayRegistrationFailedModal,
  ] = useState<boolean>(false);
  const {
    selectedAccountParams,
    selectedGroupId,
    setSelectedAccountParams,
  } = useSelectedAccountParams({ ignoreNoAccounts: true });

  const handleButtonClick = wrapWithLoader(async () => {
    await form.setValue('accountNumber', startServiceSubmitResponse?.acctId);
    await form.setValue('primaryAccountOption', 'accountNumber');
    await form.setValue('email', ssmStartServiceState?.contactInfo?.email);
    await form.setValue(
      'phoneNumber',
      ssmStartServiceState?.contactInfo?.primaryPhone,
    );
    await form.setValue('pinCode', '');
    if (ssmStartServiceState?.verifyInfo?.idType === PersonPrimaryIdType.Ssn) {
      await form.setValue(
        'ssn',
        ssmStartServiceState?.verifyInfo?.idValue?.substr(-4),
      );
      await form.setValue('secondaryAccountOption', 'ssn');
    }
    if (ssmStartServiceState?.verifyInfo?.idType !== PersonPrimaryIdType.Ssn) {
      await form.setValue(
        'driversLicense',
        ssmStartServiceState?.verifyInfo?.idValue?.substr(-4),
      );
      await form.setValue('secondaryAccountOption', 'driversLicense');
    }
    await form.setValue(
      'paperlessBilling',
      ssmStartServiceState?.homeDetails?.goPaperLess ? 'true' : 'false',
    );
    await form.handleSubmit();
  });

  useEffect(() => {
    if (!isAuthenticated) {
      if (
        ssmStartServiceState.contactInfo.createOnlineAccount &&
        !startServiceSubmitResponse?.isRegistrationSuccess
      ) {
        setDisplayRegistrationFailedModal(true);
      }
    }
  }, []);

  const setSelectedAccount = () =>
    new Promise(resolve => {
      const accountInfoForPostEnrollment: AccountIdentifier = {
        ...selectedAccountParams?.selectedAccountParams,
        accountNumber: startServiceSubmitResponse?.acctId as string,
        encryptedAccountNumber: startServiceSubmitResponse?.encryptedAccountNumber as string,
        encryptedPersonId: customerInfoData?.encryptedPersonId,
      };

      setSelectedAccountParams(selectedGroupId!, accountInfoForPostEnrollment);
      resolve(true);
    });

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.container}
      >
        <Grid item>
          <Typography className={classes.subText1} data-testid="subText1">
            {inlineRichT('WELCOME_TO_THE_NEIGHTBORHOOD', {
              FIRST_NAME: ssmStartServiceState.contactInfo.firstName,
            })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.subText2} data-testid="subText2">
            {inlineRichT('START_SERVICE_SUBMIT_SUBTEXT', {
              USER_EMAIL: ssmStartServiceState.contactInfo.email,
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.summaryCard}>
            <Box className={classes.cardFirstline}>
              <Box>
                <Stepper
                  data-testid="desktop-progress-tracker"
                  alternativeLabel
                  style={{ background: 'transparent' }}
                  className={classes.stepperIcon}
                >
                  <Step completed={true}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          completed: classes.completed,
                        },
                      }}
                    />
                  </Step>
                </Stepper>
                <Typography variant={'h2'} className={classes.cardText}>
                  <strong data-testid="cardText">{t('SERVICE_SUMMARY')}</strong>
                </Typography>
              </Box>
            </Box>

            <Box className={classes.cardListingBox}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} direction="column">
                  <Typography variant={'h2'} className={classes.listingItem}>
                    <span
                      style={
                        isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                      }
                      data-testid="label-type"
                    >
                      {t('TYPE')}:
                    </span>{' '}
                    <strong data-testid="value-type">{t('NEW')}</strong>
                  </Typography>
                  <Typography variant={'h2'} className={classes.listingItem}>
                    <span
                      style={
                        isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                      }
                      data-testid="label-for"
                    >
                      {t('FOR')}:
                    </span>{' '}
                    <strong data-testid="value-for">
                      {ssmStartServiceState.contactInfo.firstName}{' '}
                      {ssmStartServiceState.contactInfo.middleName}{' '}
                      {ssmStartServiceState.contactInfo.lastName}
                    </strong>
                  </Typography>
                  <Typography variant={'h2'} className={classes.listingItem}>
                    <span
                      style={
                        isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                      }
                      data-testid="label-at"
                    >
                      {t('AT')}:
                    </span>{' '}
                    <strong data-testid="value-at">
                      {
                        ssmStartServiceState.addressAndDate.address
                          ?.addressLine1
                      }
                    </strong>
                  </Typography>
                  <Typography
                    variant={'h2'}
                    style={
                      isMobile
                        ? { marginBottom: '0px' }
                        : { marginBottom: '17px' }
                    }
                    className={classes.listingItem}
                  >
                    <span
                      style={
                        isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                      }
                      data-testid="label-on-label"
                    >
                      {t('ON_LABEL')}:
                    </span>{' '}
                    <strong data-testid="value-on-label">
                      {ssmStartServiceState.addressAndDate.date &&
                        format(
                          ssmStartServiceState.addressAndDate.date,
                          'MM/dd/yyyy',
                        )}
                    </strong>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  direction="column"
                  className={classes.addresSection}
                >
                  <Typography variant={'h2'} className={classes.listingItem}>
                    <span data-testid="label-account-number">
                      {t('PLAN_SELECTOR_ACCOUNT_NUMBER')}:
                    </span>{' '}
                    <strong data-testid="value-account-number">
                      {startServiceSubmitResponse?.acctId}
                    </strong>
                  </Typography>
                  <Typography variant={'h2'} className={classes.listingItem}>
                    <span data-testid="label-email">{t('EMAIL')}:</span>
                    <strong data-testid="value-email" className={classes.email}>
                      {ssmStartServiceState.contactInfo.email}
                    </strong>
                  </Typography>
                  <Typography variant={'h2'} className={classes.listingItem}>
                    <span data-testid="label-phone">{t('PHONE_LABEL')}:</span>
                    <strong data-testid="value-phone">
                      {displayPhoneNumberFormat(
                        ssmStartServiceState.contactInfo.primaryPhone?.replace(
                          /[^0-9]/g,
                          '',
                        ),
                      )}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        {isAuthenticated ||
        (ssmStartServiceState.contactInfo.createOnlineAccount &&
          startServiceSubmitResponse?.isRegistrationSuccess) ? (
          <Grid item xs={12}>
            <Box className={classes.accountPref}>
              <Grid item>
                <Typography className={classes.subText3} data-testid="subtext3">
                  {t('SETUP_ACCOUNT_PREF_START_SUCCESS')}
                </Typography>
              </Grid>
              <Box className={classes.cards}>
                <Box className={classes.card}>
                  <ChangeDueDate className={classes.cardIcon} />
                  <Typography
                    className={classes.cardTitle}
                    data-testid="change-due-date-title"
                  >
                    {t('CHANGE_DUE_DATE')}
                  </Typography>
                  <Typography
                    className={classes.cardDesc}
                    data-testid="change-due-date-desc"
                  >
                    {t('CHOOSE_YOUR_BILL_DATE')}
                  </Typography>
                </Box>
                <Hidden smUp>
                  <hr className={classes.cardMobileDivider} />
                </Hidden>
                <Box className={classes.card}>
                  <SetupAutoPay className={classes.cardIcon} />
                  <Typography
                    className={classes.cardTitle}
                    data-testid="post-setup-title"
                  >
                    {t('SSM_POST_SETUP_AUTO_PAY')}
                  </Typography>
                  <Typography
                    className={classes.cardDesc}
                    data-testid="post-setup-desc"
                  >
                    {t('AUTO_PAY_INFO_LINE')}
                  </Typography>
                </Box>
                <Hidden smUp>
                  <hr className={classes.cardMobileDivider} />
                </Hidden>
                <Box className={classes.card}>
                  <ChangePreferences className={classes.cardIcon} />
                  <Typography
                    className={classes.cardTitle}
                    data-testid="account-pref-title"
                  >
                    {t('SELECT_ACCOUNT_PREFERENCES')}
                  </Typography>
                  <Typography
                    className={classes.cardDesc}
                    style={{ border: 'none' }}
                    data-testid="account-pref-desc"
                  >
                    {t('PTR_START_SERVICE_SUCCESS')}
                  </Typography>
                </Box>
              </Box>
              <Grid className={classes.doneButton}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={async () => {
                    await setSelectedAccount();
                    void navigate(
                      ROUTES.SSM_POST_ENROLLMENT_PAYMENT_PREFERENCES,
                    );
                  }}
                  data-testid="button-title"
                >
                  {t('SETUP_PREF_BUTTON')}
                </Button>
              </Grid>
            </Box>
          </Grid>
        ) : null}
        {!isAuthenticated &&
        !ssmStartServiceState.contactInfo.createOnlineAccount ? (
          <Grid item xs={12}>
            <Box className={classes.accountPref}>
              <Box className={classes.createAccount}>
                <Grid item>
                  <Typography
                    className={classes.subText4}
                    data-testid="subText4"
                  >
                    {t('CREATE_ACCOUNT_START_SUCCESS')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.subText5}
                    data-testid="subText5"
                  >
                    {t('CREATE_ACCOUNT_START_SUCCESS_SUBTEXT')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.subText6}
                    data-testid="subText6"
                  >
                    {t('CREATE_ACCOUNT_DESCRIPTION_START_SUCCESS')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.desc} data-testid="desc">
                    {inlineRichT('YOUR_ID', {
                      USER_EMAIL: ssmStartServiceState.contactInfo.email,
                    })}
                  </Typography>
                  <Grid className={classes.passwordIndicatorList}>
                    <PasswordIndicatorList password={form.values.password} />
                  </Grid>
                  <Grid item className={classes.password}>
                    <TextField
                      data-testid="password"
                      label={t('ADD_PASSWORD')}
                      inputProps={{
                        'data-testid': 'password-field',
                        minLength: 8,
                        maxLength: 16,
                      }}
                      {...form.props('password')}
                      delayedTextMask={true}
                    />
                  </Grid>
                  <Grid className={classes.doneButton}>
                    <Button
                      disabled={!Boolean(form.props('password').value)}
                      data-testid="sign-in"
                      variant={'contained'}
                      color={'primary'}
                      onClick={handleButtonClick}
                    >
                      {t('SIGN_IN_1')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Grid>
      <RegistrationFailedModal
        isOpen={displayRegistrationFailedModal}
        handleClose={() => setDisplayRegistrationFailedModal(false)}
      />
    </>
  );
};
