import React, { ReactNode } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import Button from '../../buttons';
import useAuth from '../../../hooks/useAuth';

type Props = {
  path?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      fontSize: '36px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    kicker: {
      fontSize: '36px',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    info: {
      fontSize: '18px',
      textAlign: 'center',
      marginTop: '20px',
    },
    done: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
    },
    back: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
      [theme.breakpoints.up('sm')]: {
        marginRight: '15px',
      },
    },
    action: {
      textAlign: 'center',
    },
  }),
);

type TextData = {
  header: string;
  kickerText: string;
  text: string | ReactNode;
  link: string;
  buttonText: string;
  secondaryLink: string;
  secondaryButtonText: string;
};

export const SSMStartServiceIneligible = (props: Props) => {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  const { state } = useLocation();
  const { isAuthenticated } = useAuth();

  // @ts-ignore
  const isMultiplePremisesError = state?.errorType === 'multiplePremises';
  // @ts-ignore
  const isExperianNotFoundError = state?.errorType === 'notFound';
  const isPendingStartIneligibleError =
    // @ts-ignore
    state?.errorType === 'pendingStartIneligibleError';

  const textData: TextData = {
    header: t('SSM_START_INELIGIBLE_HEADER_TEXT'),
    kickerText: t('SSM_START_INELIGIBLE_KICKER_TEXT'),
    text: t('SSM_START_INELIGIBLE_INFO_TEXT'),
    link: ROUTES.SSM_HOME,
    buttonText: t('DONE'),
    secondaryLink: '',
    secondaryButtonText: t('BACK'),
  };
  if (
    // @ts-ignore
    state?.errorType === 'notFound' ||
    // @ts-ignore
    state?.errorType === 'verifyInfoError'
  ) {
    textData.header = t('APPLICANT_NOT_FOUND');
    textData.kickerText = t('SSM_START_INELIGIBLE_NOT_FOUND_KICKER_TEXT');
    textData.text = inlineRichT('CALL_US_NOT_FOUND_TEXT', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
    // @ts-ignore
  } else if (state?.errorType === 'isFraud') {
    textData.header = t('INELIGIBLE_TO_START_ON_LINE');
    textData.link = ROUTES.SSM_START_CONTACT;
    textData.text = inlineRichT('SSM_MOVE_CALL_FOR_ASSISTANCE', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
    // @ts-ignore
  } else if (state?.errorType === 'isBadDebtisFraud') {
    textData.header = t('INELIGIBLE_TO_START_ON_LINE');
    textData.text = inlineRichT('SSM_START_INELIGIBLE_INFO_TEXT', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
    // @ts-ignore
  } else if (state?.errorType === 'isBadDebtisFraudExperian') {
    textData.header = t('INELIGIBLE_TO_START_ON_LINE');
    textData.text = inlineRichT('SSM_START_INELIGIBLE_INFO_TEXT', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
    textData.link = isAuthenticated
      ? ROUTES.SSM_START_ADDRESS
      : ROUTES.SSM_START_CONTACT;
    // @ts-ignore
  } else if (isPendingStartIneligibleError) {
    textData.header = t('ALREADY_SET_A_START_DATE');
    textData.kickerText = t('CHANGE_IF_NEED_TO');
    textData.text = inlineRichT('PENDING_INELIGIBLE_DESC', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
    textData.link = isAuthenticated ? ROUTES.ACCOUNT : ROUTES.HOME;
    textData.secondaryLink = ROUTES.SSM_START_ADDRESS;
  } else if (isExperianNotFoundError) {
    textData.secondaryButtonText = t('TRY_AGAIN');
    textData.secondaryLink = ROUTES.SSM_START_VERIFY;
  } else if (isMultiplePremisesError) {
    textData.header = t('MULTIPLE_PREMISES_FOUND_TITLE');
    textData.text = inlineRichT('MULTIPLE_PREMISES_CALL_CS', {
      CUSTOMER_CARE_NUMBER_OTHER: t('CUSTOMER_CARE_NUMBER_OTHER'),
    });
    textData.secondaryLink = ROUTES.SSM_START_ADDRESS;
  }

  return (
    <Grid item className={classes.container}>
      <Grid item>
        <Typography className={classes.header} data-testid="header">
          {textData.header}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.kicker} data-testid="kicker">
          {textData.kickerText}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.info} data-testid="info">
          {textData.text}
        </Typography>
      </Grid>
      <Grid item className={classes.action}>
        {isMultiplePremisesError || isExperianNotFoundError  ? (
          <Button
            color={'secondary'}
            variant={'outlined'}
            className={classes.back}
            onClick={() => navigate(textData.secondaryLink)}
            data-testid="back"
          >
            {textData.secondaryButtonText}
          </Button>
        ) : null}
        <Button
          color={'primary'}
          variant={'contained'}
          className={classes.done}
          onClick={() => navigate(textData.link)}
          data-testid="done"
        >
          {textData.buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};
