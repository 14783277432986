import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  Box,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import moment from 'moment';
import { SSMStartServiceState } from '../../../hooks/useSSMStartService';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import colors from '../../../themes/main-colors';
import { Link } from 'gatsby';
import ROUTES from '../../../routes';
import { Meter, PropertyUsageType } from '../ssm.types';
import useAuth from '../../../hooks/useAuth';
import { displayPhoneNumberFormat } from '../../../util/format';
import { PersonPrimaryIdType } from '../../../__generated__/pge-types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '16px',
    },
    summaryCardContainer: {
      background: colors.lightGray2,
      boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
      padding: '25px',
      borderRadius: '5px',
      width: '100%',
      marginTop: '16px',
    },
    summaryCardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    summaryCardHeading: {
      textTransform: 'capitalize',
      fontSize: '16px',
      color: colors.lightGray4,
      fontWeight: 'bold',
    },
    summaryCardEdit: {
      fontSize: '16px',
      textDecoration: 'none',
    },
    divider: {
      color: colors.lightGray1,
      marginTop: '16px',
      marginBottom: '16px',
    },
    summaryCardBodyLabel: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    summaryCardBodyValue: {
      fontSize: '14px',
    },
    summaryCardBodySection: {
      marginLeft: '25px',
      marginBottom: '5px',
    },
    marginTop: {
      marginTop: '5px',
    },
    meter: {
      width: '100%',
      marginBottom: '5px',
      '& > p': {
        fontSize: '14px',
      },
    },
    textColor: {
      color: colors.noirBlur,
    },
  }),
);

type SsmStartSummaryType = {
  title: string;
  link: string;
  info: {
    label: string;
    value: string | ReactNode;
    meters?: Meter[];
  }[];
}[];

export const SSMConfirmDetails = ({
  componentProps,
}: WizardFormProps<SSMStartServiceState>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const {
    metersInfo: meters,
    ssmStartServiceState,
    concatenateAddressFields,
    isRenewablesEligible,
  } = componentProps;
  const idTypes: Record<string, any> = {
    [PersonPrimaryIdType.Ssn]: t('SOCIAL_SECURITY_NUMBER'),
    [PersonPrimaryIdType.Dl]: `${t('DRIVERS_LICENSE')} / ${t('STATE_ID_CARD')}`,
    [PersonPrimaryIdType.Matr]: t('MATRICULA_ID'),
    [PersonPrimaryIdType.Pssprt]: t('PASSPORT'),
    [PersonPrimaryIdType.Resalien]: t('RESIDENT_ALIEN_ID'),
    [PersonPrimaryIdType.Visa]: t('VISA_ID'),
    [PersonPrimaryIdType.Military]: t('MILITARY_ID'),
    [PersonPrimaryIdType.Ortrib]: t('OREGON_TRIBAL_ID'),
  };
  const propertyUsageType: Record<string, any> = {
    [PropertyUsageType.Own]: t('OWNER'),
    [PropertyUsageType.TNT]: t('TENANT'),
    [PropertyUsageType.LLD]: t('LANDLORD'),
    [PropertyUsageType.FNF]: t('FRIEND_FAMILY'),
  };
  let fullName: string = '';
  if (ssmStartServiceState?.contactInfo) {
    fullName = `${ssmStartServiceState?.contactInfo?.firstName ??
      ''} ${ssmStartServiceState?.contactInfo?.middleName ??
      ''} ${ssmStartServiceState?.contactInfo?.lastName ?? ''}`.trim();
  }
  let fullAddress: string = '';
  fullAddress = `${
    ssmStartServiceState?.addressAndDate?.address?.addressLine1
      ? ssmStartServiceState?.addressAndDate?.address?.addressLine1 + ','
      : ''
  } ${
    ssmStartServiceState?.addressAndDate?.address?.city
      ? ssmStartServiceState?.addressAndDate?.address?.city + ','
      : ''
  } ${
    ssmStartServiceState?.addressAndDate?.address?.postal
      ? ssmStartServiceState?.addressAndDate?.address?.postal
      : ''
  }`.trim();

  const ssmStartSummary: SsmStartSummaryType = [
    {
      title: t('ADDRESS_DETAILS'),
      link: ROUTES.SSM_START_ADDRESS,
      info: [
        {
          label: t('WHERE_DO_YOU_WANT_TO_START_SERVICE'),
          value: fullAddress,
          meters,
        },
        {
          label: t('WHEN_DO_YOU_WANT_TO_START_SERVCE'),
          value: moment(ssmStartServiceState?.addressAndDate.date).format(
            'MM/DD/YYYY',
          ),
        },
      ],
    },
    ...(!isAuthenticated
      ? [
          {
            title: t('CONTACT_INFO_LABEL'),
            link: ROUTES.SSM_START_CONTACT,
            info: [
              {
                label: t('MAIN_CUSTOMER_NAME'),
                value: fullName,
              },
              {
                label: t('PRIMARY_PHONE'),
                value: displayPhoneNumberFormat(
                  ssmStartServiceState.contactInfo.primaryPhone?.replace(
                    /[^0-9]/g,
                    '',
                  ),
                ),
              },
              ...(ssmStartServiceState.contactInfo.mobilePhone
                ? [
                    {
                      label: t('MOB_PHONE'),
                      value: displayPhoneNumberFormat(
                        ssmStartServiceState.contactInfo.mobilePhone?.replace(
                          /[^0-9]/g,
                          '',
                        ),
                      ),
                    },
                  ]
                : []),
              ...(ssmStartServiceState.contactInfo.altPhone
                ? [
                    {
                      label: t('ALT_PHONE'),
                      value: displayPhoneNumberFormat(
                        ssmStartServiceState.contactInfo.altPhone?.replace(
                          /[^0-9]/g,
                          '',
                        ),
                      ),
                    },
                  ]
                : []),
              {
                label: t('EMAIL'),
                value: ssmStartServiceState.contactInfo.email,
              },
              {
                label: t('CREATE_ONLINE_ACCOUNT') + '?',
                value: ssmStartServiceState.contactInfo.createOnlineAccount
                  ? t('YES')
                  : t('NO'),
              },
            ],
          },
        ]
      : []),
    {
      title: t('PERSONAL_INFO_LABEL'),
      link: ROUTES.SSM_START_VERIFY,
      info: [
        {
          label: t('ID_TYPE_PROVIDED'),
          value: idTypes[ssmStartServiceState.verifyInfo.idType],
        },
        ...(ssmStartServiceState?.verifyInfo?.birthdate
          ? [
              {
                label: t('DOB_LABEL'),
                value: moment(
                  ssmStartServiceState?.verifyInfo?.birthdate,
                ).format('MM/DD/YYYY'),
              },
            ]
          : []),
      ],
    },
    {
      title: t('HOME_DETAILS'),
      link: ROUTES.SSM_START_HOME,
      info: [
        {
          label: t('HOW_WILL_YOU_USE_THIS_PROPERTY'),
          value:
            propertyUsageType[ssmStartServiceState.homeDetails.propertyUsage],
        },
        {
          label: t('BILLING_PREF'),
          value: ssmStartServiceState.homeDetails.goPaperLess
            ? t('GO_PAPERLESS')
            : t('MAIL_BILL'),
        },
        {
          label: t('MAILING_ADDRESS_LABEL'),
          value: ssmStartServiceState.homeDetails.sameAsServiceAddress
            ? fullAddress
            : [
                ssmStartServiceState.homeDetails.mailAddress || '',
                ', ',
                ssmStartServiceState.homeDetails.mailCity || '',
                ', ',
                ssmStartServiceState.homeDetails.mailZip || '',
              ].join(''),
        },
        ...(isRenewablesEligible
          ? [
              {
                label: t('ENROLL_IN_GREEN_FUTURE'),
                value: ssmStartServiceState.homeDetails.enrollInGreenFuture
                  ? t('YES')
                  : t('NO'),
              },
            ]
          : []),
        {
          label: t('RECEIVE_NOTIFICATIONS'),
          value: ssmStartServiceState.homeDetails.shouldReceiveNotification ? (
            <span>
              {t('YES')}
              <br />
              {displayPhoneNumberFormat(
                ssmStartServiceState.homeDetails.registeredMobileNumber?.replace(
                  /[^0-9]/g,
                  '',
                ),
              )}
            </span>
          ) : (
            t('NO')
          ),
        },
      ],
    },
  ];

  const handleClick = (link: string) => {
    if (link === ROUTES.SSM_START_ADDRESS) {
      concatenateAddressFields();
    }
  };

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={classes.container}
    >
      <Grid item container spacing={2}>
        <Typography data-testid="confirm-subtext">
          {t('SSM_START_CONFIRM_SUBTEXT')}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Box className={classes.summaryCardContainer}>
          {ssmStartSummary.map((cardContent, index) => (
            <Box key={index}>
              <Box className={classes.summaryCardHeader}>
                <Typography
                  className={classes.summaryCardHeading}
                  data-testid={`card-heading-${index + 1}`}
                >
                  {cardContent.title}
                </Typography>
                <Link
                  to={cardContent.link}
                  className={classes.summaryCardEdit}
                  data-testid={`header-link-${index + 1}`}
                  onClick={() => handleClick(cardContent.link)}
                >
                  {t('EDIT')}
                </Link>
              </Box>
              <Box>
                {cardContent.info.map((body, bodyIndex) => (
                  <Box
                    className={classes.summaryCardBodySection}
                    key={bodyIndex}
                  >
                    <Typography
                      className={classes.summaryCardBodyLabel}
                      data-testid={`body-label-${index + 1}${bodyIndex + 1}`}
                    >
                      {body.label}
                    </Typography>
                    <Typography
                      className={classes.summaryCardBodyValue}
                      data-testid={`body-value-${index + 1}${bodyIndex + 1}`}
                    >
                      {body.value}
                    </Typography>
                    {body?.meters?.length ? (
                      <Box className={classes.marginTop}>
                        {body?.meters
                          .filter((meter: Meter) => meter.checked)
                          .map((meter: Meter, meterIndex: number) => (
                            <Box className={classes.meter} key={meterIndex}>
                              <Typography data-testid="meter-index">{`${t(
                                'METER',
                              ).toUpperCase()} ${meterIndex + 1}`}</Typography>
                              <Typography data-testid="meter-type">
                                {t('TYPE')}
                                {': '}
                                <b className={classes.textColor}>
                                  {meter.spDesc}
                                </b>
                              </Typography>
                              {meter.locationDesc ? (
                                <Typography data-testid="meter-location">
                                  {t('LOCATION')}
                                  {': '}
                                  <b className={classes.textColor}>
                                    {meter.locationDesc}
                                  </b>
                                </Typography>
                              ) : meter.meterNumber ? (
                                <Typography data-testid="meter-number">
                                  {t('METER_NO')}
                                  {': '}
                                  <b className={classes.textColor}>
                                    {meter.meterNumber}
                                  </b>
                                </Typography>
                              ) : null}
                            </Box>
                          ))}
                      </Box>
                    ) : null}
                  </Box>
                ))}
              </Box>
              {index !== ssmStartSummary.length - 1 ? (
                <hr className={classes.divider} />
              ) : null}
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
