import {
  Grid,
  makeStyles,
  createStyles,
  Checkbox,
  Box,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { convertValidationRules } from '../../../hooks/useFormState';
import TextField from '../../text-field';
import PhoneTextField from '../../phone-text-field';
import EmailTextField from '../../email-text-field';
import PasswordTextField from '../../password-text-field';
import colors from '../../../themes/main-colors';
import {
  validateAlphabetCharacters,
  validateEmail,
  validateExistsAndAlphabetCharacters,
  validatePassword,
  validatePhoneNumber,
} from '../../../util/form-validation';
import { BaseValidationContext } from '../../../hooks/useFormState.types';
import { ContactInfo } from '../../../hooks/useSSMStartService';
import { CustomerFound } from './CustomerFound/CustomerFound';
import { CSSWebUser } from './CSSWebUser/CSSWebUser';
import PasswordIndicatorList from '../../password-indicator-list';
import routes from '../../../routes';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '16px',
      marginBottom: '16px',
    },
    formField: {
      width: '100%',
      background: 'none',
    },
    removePadding: {
      padding: 0,
    },
    marginBottom: {
      marginBottom: '-8px',
    },
    marginTop: {
      marginTop: '15px',
    },
    backgroundColor: {
      background: colors.backgroundLightBlue,
    },
    subText: {
      fontSize: '14px',
      margin: '10px 0 10px 0',
      padding: '10px',
    },
    emailExistsError: {
      color: colors.roseRed,
      fontSize: '0.75rem',
      padding: '0 10px 0 10px',
    },
  }),
);

export const SSMContactInfoForm = ({
  formState,
  componentProps,
}: WizardFormProps<ContactInfo>) => {
  const { t, inlineRichT, richT } = useTranslation();
  const classes = useStyles();
  const {
    isExistingEmail,
    setIsExistingEmail,
    isCustomerFoundModalDisplayed,
    setIsCustomerFoundModalDisplayed,
    validatePersonData,
    loginEmail,
    setLoginEmail,
  } = componentProps;

  const [isCSSWebUserModalDisplayed, setIsCSSWebUserModalDisplayed] = useState<
    boolean
  >(false);

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExistingEmail(false);
    await formState.props('email').onChange(e);
  };

  const handleCSSWebUserClose = () => {
    setIsCSSWebUserModalDisplayed(false);
    document.getElementById('createOnlineAccount')?.scrollIntoView();
  };

  const handleCustomerFoundClose = () => {
    setIsCustomerFoundModalDisplayed(false);
    setLoginEmail(undefined);
  };

  useEffect(() => {
    setIsCustomerFoundModalDisplayed(false);
    setIsCSSWebUserModalDisplayed(false);
  }, []);

  React.useEffect(() => {
    if (formState.values.useForMobile) {
      void formState.setValue('mobilePhone', formState.values.primaryPhone);
    }
  }, [formState.values.useForMobile]);

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid container direction="column" spacing={2}>
          <Grid item container>
            <TextField
              name={'firstName'}
              label={t('FIRST_NAME_1')}
              className={classes.formField}
              data-testid="firstName"
              {...formState.props('firstName')}
            />
          </Grid>
          <Grid item container>
            <TextField
              name={'middleName'}
              label={t('MIDDLE_NAME_OR_INITIAL')}
              className={classes.formField}
              data-testid="middleName"
              {...formState.props('middleName')}
            />
          </Grid>
          <Grid item container>
            <TextField
              name={'lastName'}
              label={t('LAST_NAME_1')}
              className={classes.formField}
              data-testid="lastName"
              {...formState.props('lastName')}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.marginTop}>
          <Grid item container className={classes.marginBottom}>
            <PhoneTextField
              name={'primaryPhone'}
              label={t('PRIMARY_PHONE')}
              className={classes.formField}
              inputProps={{ minLength: 1, maxLength: 15 }}
              data-testid="primaryPhone"
              {...formState.props('primaryPhone')}
            />
          </Grid>
          <Grid item container className={classes.marginBottom}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'useForMobile'}
                    color={'primary'}
                    data-testid="useForMobile"
                    checked={formState.values.useForMobile}
                    {...formState.props('useForMobile')}
                  />
                }
                label={t('USE_FOR_MOBILE')}
              />
            </Box>
          </Grid>
          {!formState.values.useForMobile && (
            <Grid item container className={classes.marginBottom}>
              <PhoneTextField
                name={'mobilePhone'}
                label={t('MOBILE_PHONE_OPTIONAL')}
                className={classes.formField}
                inputProps={{ minLength: 1, maxLength: 15 }}
                data-testid="mobilePhone"
                {...formState.props('mobilePhone')}
              />
            </Grid>
          )}
          <Grid item container className={classes.marginBottom}>
            <PhoneTextField
              name={'altPhone'}
              label={t('ALT_PHONE_OPTIONAL')}
              className={classes.formField}
              inputProps={{ minLength: 1, maxLength: 15 }}
              data-testid="altPhone"
              {...formState.props('altPhone')}
            />
          </Grid>
          <Grid item container className={classes.marginBottom}>
            <Typography className={classes.subText}>
              {richT('PHONE_SMS_DISCLAIMER', {
                PRIVACY_POLICY_LINK: routes.PRIVACY_POLICY,
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.marginTop}>
          <Grid item container className={classes.marginBottom}>
            <EmailTextField
              name={'email'}
              label={t('EMAIL')}
              id="email"
              className={classes.formField}
              {...formState.props('email')}
              onChange={handleEmailChange}
              data-testid="email"
              error={formState.props('email').error || Boolean(isExistingEmail)}
            />
            {isExistingEmail && (
              <Typography className={classes.emailExistsError}>
                {inlineRichT('EMAIL_EXISTS_ERROR', {
                  email: formState.values.email,
                })}
              </Typography>
            )}
          </Grid>
          <Grid item container className={classes.marginBottom}>
            <Typography
              className={`${classes.subText} ${classes.backgroundColor}`}
            >
              {t('EMAIL_DISCLAIMER')}
            </Typography>
          </Grid>
          <Grid item container className={classes.marginBottom}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'createOnlineAccount'}
                    id="createOnlineAccount"
                    color={'primary'}
                    data-testid="createOnlineAccount"
                    checked={formState.values.createOnlineAccount}
                    {...formState.props('createOnlineAccount')}
                  />
                }
                label={t('CREATE_ONLINE_ACCOUNT')}
              />
            </Box>
          </Grid>
          {formState.values.createOnlineAccount && (
            <Grid item container className={classes.marginBottom}>
              <PasswordTextField
                name={'password'}
                label={t('PASSWORD')}
                className={classes.formField}
                inputProps={{ minLength: 8, maxLength: 16 }}
                data-testid="password"
                {...formState.props('password')}
              />

              <PasswordIndicatorList password={formState.values.password!} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <CustomerFound
        isOpen={isCustomerFoundModalDisplayed}
        handleClose={handleCustomerFoundClose}
        openCSSWebUserModal={() => setIsCSSWebUserModalDisplayed(true)}
        createOnlineAccount={formState?.values?.createOnlineAccount || false}
        loginEmail={loginEmail}
      />
      <CSSWebUser
        isOpen={isCSSWebUserModalDisplayed}
        handleClose={handleCSSWebUserClose}
        oldEmail={validatePersonData?.username || ''}
        setLoginEmail={() => setLoginEmail(validatePersonData?.username || '')}
        openCustomerFoundModal={() => setIsCustomerFoundModalDisplayed(true)}
        closeCSSWebUserModal={() => setIsCSSWebUserModalDisplayed(false)}
      />
    </>
  );
};

export const createContactInfoFormAdapter = () => {
  return {
    toFormState: (initial: ContactInfo): ContactInfo => ({
      firstName: initial.firstName,
      middleName: initial.middleName,
      lastName: initial.lastName,
      primaryPhone: initial.primaryPhone,
      useForMobile: initial.useForMobile ?? false,
      mobilePhone: initial.mobilePhone,
      altPhone: initial.altPhone,
      email: initial.email,
      createOnlineAccount: initial.createOnlineAccount ?? true,
      password: initial.password,
    }),
    fromFormState: (state: ContactInfo): ContactInfo => {
      return {
        firstName: state.firstName,
        middleName: state.middleName,
        lastName: state.lastName,
        primaryPhone: state.primaryPhone,
        useForMobile: state.useForMobile,
        mobilePhone: state.mobilePhone,
        altPhone: state.altPhone,
        email: state.email,
        createOnlineAccount: state.createOnlineAccount,
        password: state.password,
      };
    },
  };
};

export const createContactInfoValidateFunction = () => {
  const { t } = useTranslation();
  return convertValidationRules<ContactInfo>(
    (context: BaseValidationContext<ContactInfo>) => {
      const {
        values: { useForMobile, createOnlineAccount, mobilePhone, altPhone },
      } = context;
      return {
        firstName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('FIRST_NAME_1'),
        ),
        middleName: validateAlphabetCharacters.bind(
          null,
          t('MIDDLE_NAME_OR_INITIAL'),
        ),
        lastName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('LAST_NAME_1'),
        ),
        primaryPhone: validatePhoneNumber,
        useForMobile: () => null,
        mobilePhone:
          !useForMobile && Number(mobilePhone?.length) > 0
            ? validatePhoneNumber
            : () => null,
        altPhone:
          Number(altPhone?.length) > 0 ? validatePhoneNumber : () => null,
        email: validateEmail,
        createOnlineAccount: () => null,
        password: createOnlineAccount ? validatePassword : () => null,
      };
    },
  );
};
