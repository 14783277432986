import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from '../../../../hooks/useTranslation';
import Modal from '../../../modal';
import Button from '../../../buttons';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';
import { navigate } from 'gatsby';
import ROUTES from '../../../../routes';
import TextLink from '../../../text-link';

type CSSWebUserProps = {
  isOpen: boolean;
  handleClose: () => void;
  oldEmail: string;
  setLoginEmail: () => void;
  openCustomerFoundModal: () => void;
  closeCSSWebUserModal: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '32px',
      color: colors.noirBlur,
      marginBottom: '6px',
    },
    container: {
      maxWidth: '680px',
    },
    body: {
      backgroundColor: colors.modalBackgroundColor,
      marginTop: '20px',
      padding: '10px 25px 10px 25px',
      borderRadius: '4px',
      boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
    },
    bodyText: {
      fontSize: '18px',
      color: colors.noirBlur,
      marginTop: '10px',
      [theme.breakpoints.down('sm')]: {
        wordBreak: 'break-all',
      },
    },
    marginBottom: {
      marginBottom: '6px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '20px',
    },
    back: {
      fontSize: '18px',
      padding: '5px 25px 5px 25px',
      [theme.breakpoints.down('sm')]: {
        width: '45%',
      },
    },
    okay: {
      fontSize: '18px',
      color: colors.white,
      padding: '5px 25px 5px 25px',
      marginLeft: '20px',
      [theme.breakpoints.down('sm')]: {
        width: '45%',
        marginLeft: '10px',
      },
    },
  }),
);

export const CSSWebUser = (props: CSSWebUserProps) => {
  const {
    isOpen,
    handleClose,
    oldEmail,
    setLoginEmail,
    openCustomerFoundModal,
    closeCSSWebUserModal,
  } = props;
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setLoginEmail();
    closeCSSWebUserModal();
    openCustomerFoundModal();
  };

  return (
    <Modal
      open={isOpen}
      showCloseIcon={true}
      onClose={handleClose}
      title={
        <Typography variant="body1" className={classes.title}>
          {''}
        </Typography>
      }
    >
      <Grid container className={classes.container}>
        <Grid container className={classes.body}>
          <Typography
            className={classes.bodyText}
            data-testid="modal-body-text-1"
          >
            {inlineRichT('CSS_WEB_USER_ERROR_1', {
              oldEmail,
            })}
          </Typography>
          <Typography
            className={`${classes.bodyText} ${classes.marginBottom}`}
            data-testid="modal-body-text-2"
          >
            {inlineRichT('CSS_WEB_USER_ERROR_2')}
            <TextLink onClick={handleClick}>{t('SIGN_IN_2')}</TextLink>
            {inlineRichT('CSS_WEB_USER_ERROR_3')}
          </Typography>
        </Grid>
        <Grid container className={classes.actions}>
          <Button
            color={'secondary'}
            variant={'outlined'}
            className={classes.back}
            onClick={handleClose}
            data-testid="modal-back-button"
          >
            {t('CANCEL')}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            className={classes.okay}
            onClick={() => navigate(ROUTES.SSM_START_VERIFY)}
            data-testid="modal-okay-button"
          >
            {t('CONTINUE')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
